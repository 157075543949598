import React, { type MouseEvent, useMemo, useRef, useState } from "react";
import "css/PreviewWebsite/Container/NavbarContainer.scss";
import { useForm } from "Providers/FormProvider";
import classNames from "classnames";
import { getColorHexByKey, getContrastColorHexByKey } from "helpers";
import {
	Check2,
	ChevronDown,
	ChevronRight,
	CloudRainHeavy,
} from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import HeaderButton from "tsx/Components/PreviewWebsite/HeaderButton";
import Logo from "tsx/Components/PreviewWebsite/Logo";
import { usePreviewWebsiteHandler } from "tsx/PreviewWebsite/PreviewWebsite";
import BurgerMenuLarge from "./Menus/BurgerMenuLarge";
import BurgerMenuSmall from "./Menus/BurgerMenuSmall";
import SearchbarContainer from "./SearchbarContainer";

const NavbarItems: string[] = [
	"preview.navbar.sitemap",
	"preview.navbar.menu_item_1",
	"preview.navbar.menu_item_2",
	"preview.navbar.menu_item_3",
];

export default function NavbarContainer({
	searchbarVisible = false,
	searchButtonClick,
	burgerMenuClick,
}: {
	searchbarVisible?: boolean;
	searchButtonClick?: () => void;
	burgerMenuClick?: () => void;
}) {
	const { t } = useTranslation();
	const { form } = useForm();
	const { setLocation } = usePreviewWebsiteHandler();
	const [navbarHover, setNavbarHover] = useState<boolean>(false);
	const refMegaMenu = useRef(null);

	const navbarWrapperTop = useMemo<number>(() => {
		let top = 0;

		if (form.header.behavior !== "relative") {
			if (form.navigation.topNavigation) {
				top += 40;

				if (form.header.hasBorderTop) {
					top += 6;
				}
			}

			if (form.header.size === "large") {
				top += 172;
			}
		}

		return top;
	}, [form]);

	const onMouseLeaveNavbar = (event: MouseEvent) => {
		if (event.relatedTarget !== refMegaMenu.current) {
			setNavbarHover(false);
		}
	};

	return (
		<div
			className={classNames({
				"navbar-wrapper": true,
				sticky:
					form.navigation.behavior === "sticky" ||
					form.header.behavior !== "relative",
				boxed: form.layout.layout === "boxed",
				framed:
					form.layout.layout === "framed" && form.header.behavior !== "fixed",
			})}
			style={{
				top: navbarWrapperTop,
			}}
		>
			<div
				className={classNames({
					navbar: true,
					sticky:
						form.navigation.behavior === "sticky" ||
						form.header.behavior !== "relative",
					"logo-height": form.header.size === "small",
				})}
				style={{
					backgroundColor: getColorHexByKey(
						form,
						form.navigation.menuBackgroundColor,
					),
					justifyContent:
						form.header.size === "large"
							? "space-between"
							: form.header.logoPosition === "right"
								? "start"
								: "end",
				}}
			>
				{form.header.navigationStyle !== "burger" && (
					<div className="navbar-items">
						{NavbarItems.map((item, index) => (
							<span
								key={item}
								onClick={() => setLocation("subpage")}
								onMouseLeave={onMouseLeaveNavbar}
								onMouseEnter={() => setNavbarHover(true)}
								style={{
									color: getColorHexByKey(form, form.navigation.menuFontColor),
								}}
							>
								{t(item)}
								{index !== 1 && form.navigation.chevronMainNav && (
									<ChevronRight
										color={getColorHexByKey(
											form,
											form.navigation.menuFontColor,
										)}
										stroke={getColorHexByKey(
											form,
											form.navigation.menuFontColor,
										)}
									/>
								)}
								{index === 1 && form.navigation.chevronMainNav && (
									<ChevronDown
										color={getColorHexByKey(
											form,
											form.navigation.menuFontColor,
										)}
										stroke={getColorHexByKey(
											form,
											form.navigation.menuFontColor,
										)}
									/>
								)}
								{index === 1 && form.header.navigationStyle === "drop-down" && (
									<div
										className={classNames({
											menu: true,
											"drop-down": true,
										})}
										style={{ top: form.header.size === "large" ? 56 : 100 }}
									>
										<span>{t("preview.navbar.submenu_item")}</span>
										<span>{t("preview.navbar.submenu_item")}</span>
										<span>{t("preview.navbar.submenu_item")}</span>
									</div>
								)}
							</span>
						))}
					</div>
				)}
				{form.header.navigationStyle === "mega-menu" && navbarHover && (
					<div
						className="mega-menu"
						onMouseLeave={() => setNavbarHover(false)}
						ref={refMegaMenu}
						style={{ top: form.header.size === "large" ? 56 : 100 }}
					>
						<span>{t("preview.navbar.submenu_item")}</span>
						<span>{t("preview.navbar.submenu_item")}</span>
						<span className="indented">{t("preview.navbar.submenu_item")}</span>
						<span>{t("preview.navbar.submenu_item")}</span>
					</div>
				)}
				<div
					className={classNames({
						"navbar-buttons": true,
						"burger-menu-special":
							form.header.navigationStyle === "burger" &&
							form.header.size === "large",
						"burger-menu-special-small":
							form.header.navigationStyle === "burger" &&
							form.header.size === "small",
					})}
				>
					{form.header.navigationStyle === "burger" &&
						form.header.size === "large" && (
							<BurgerMenuLarge form={form} burgerMenuClick={burgerMenuClick} />
						)}
					{form.header.navigationStyle === "burger" &&
						form.header.size === "small" && (
							<BurgerMenuSmall
								form={form}
								burgerMenuClick={burgerMenuClick}
								searchButtonClick={searchButtonClick}
							/>
						)}

					{form.header.hasButton &&
						(form.header.buttonPosition === "bottom" ||
							form.header.size === "small") &&
						form.header.navigationStyle !== "burger" && (
							<HeaderButton icon={<Check2 />} label="Test BTN" />
						)}
					{form.header.size !== "large" &&
						form.header.navigationStyle !== "burger" && (
							<HeaderButton
								label={t("search")}
								isSearchButton
								onClick={() => searchButtonClick?.()}
							/>
						)}
					{form.weather.hasWeatherWidget &&
						(form.header.navigationStyle !== "burger" ||
							form.header.size === "small") && (
							<HeaderButton
								icon={<CloudRainHeavy />}
								label="9°"
								style={{
									backgroundColor:
										form.weather.buttonStyle === "fill"
											? getColorHexByKey(form, form.weather.buttonColor)
											: "transparent",
									color:
										form.weather.buttonStyle !== "fill"
											? getColorHexByKey(form, form.weather.buttonColor)
											: getContrastColorHexByKey(
													form,
													form.weather.buttonColor,
												),
									borderColor: getColorHexByKey(form, form.weather.buttonColor),
								}}
							/>
						)}
				</div>
				{form.header.size === "small" && (
					<div
						className={classNames({
							"navbar-logo": true,
							right: form.header.logoPosition === "right",
							left: form.header.logoPosition === "left",
						})}
					>
						<Logo />
					</div>
				)}
			</div>
			<SearchbarContainer
				visible={searchbarVisible && form.search.style !== "overlay"}
			/>
		</div>
	);
}
