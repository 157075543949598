import { useForm } from "Providers/FormProvider";
import { type CSSProperties, useMemo } from "react";
import { ReactComponent as SVGLogo } from "svg/website/logo.svg";

export default function Logo({
	disableAutoStyle = false,
}: {
	disableAutoStyle?: boolean;
}) {
	const { form } = useForm();

	const getStylesForLogo = useMemo<CSSProperties | undefined>(() => {
		if (disableAutoStyle) {
			return {};
		}
		return {
			left: form.header.logoPosition === "left" ? 40 : undefined,
			right: form.header.logoPosition === "right" ? 40 : undefined,
		};
	}, [form, disableAutoStyle]);

	return <SVGLogo style={getStylesForLogo} />;
}
