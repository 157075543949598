import React, { useRef, useState } from "react";
import "css/Frames/SitePreviewFrame.scss";
import { useForm } from "Providers/FormProvider";
import classNames from "classnames";
import { ReactComponent as BrowserButton } from "svg/browser-button.svg";
import PreviewWebsite from "tsx/PreviewWebsite/PreviewWebsite";

export default function SitePreviewFrame() {
	const rootRef = useRef<HTMLDivElement | null>(null);
	const { form } = useForm();
	const [searchbarVisible, setSearchbarVisible] = useState<boolean>(false);
	const [burgerMenuVisible, setBurgerMenuVisible] = useState<boolean>(false);
	const [fakeLoaderVisible, setFakeLoaderVisible] = useState<boolean>(false);

	return (
		<div className="preview-frame">
			<div className="site-preview-frame">
				<div className="browser-header">
					<div className="browser-buttons">
						<BrowserButton />
						<BrowserButton />
						<BrowserButton />
					</div>
					<div className="browser-bar" />
				</div>
				<div
					className={classNames({
						"browser-content": true,
						"searchbar-overlay":
							(searchbarVisible && form.search.style === "overlay") ||
							(burgerMenuVisible && form.header.navigationStyle === "burger") ||
							fakeLoaderVisible,
					})}
					ref={rootRef}
				>
					<PreviewWebsite
						onScrollToTop={() =>
							rootRef?.current?.scroll({
								top: 0,
								behavior: "smooth",
							})
						}
						searchbarVisible={searchbarVisible}
						burgerMenuVisible={burgerMenuVisible}
						fakeLoaderVisible={fakeLoaderVisible}
						setSearchbarVisible={setSearchbarVisible}
						setBurgerMenuVisible={setBurgerMenuVisible}
						setFakeLoaderVisible={setFakeLoaderVisible}
					/>
				</div>
			</div>
		</div>
	);
}
