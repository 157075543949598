import { useForm } from "Providers/FormProvider";
import { DROPDOWN_OPTIONS_COLORS } from "helpers";
import React from "react";
import Dropdown from "tsx/Components/Inputs/Dropdown";

export default function SearchForm() {
	const { form, setFormSearchValue } = useForm();

	return (
		<div className="form-frame">
			<Dropdown
				label="forms.search.style"
				value={form.search.style}
				onChange={(val: any) => setFormSearchValue("style", val)}
				options={[
					{ key: "forms.search.style.bar", value: "bar" },
					{ key: "forms.search.style.collapse", value: "collapse" },
					{ key: "forms.search.style.overlay", value: "overlay" },
				]}
			/>
			<Dropdown
				form={form}
				label="forms.search.searchbar_background_color"
				value={form.search.searchbarBackgroundColor}
				showColorPreview
				onChange={(val: any) =>
					setFormSearchValue("searchbarBackgroundColor", val)
				}
				options={DROPDOWN_OPTIONS_COLORS}
			/>
			<Dropdown
				form={form}
				label="forms.search.button_background_color"
				value={form.search.buttonBackgroundColor}
				showColorPreview
				onChange={(val: any) =>
					setFormSearchValue("buttonBackgroundColor", val)
				}
				options={DROPDOWN_OPTIONS_COLORS.filter(
					(color) => color.key !== "color.white",
				)}
			/>
			<Dropdown
				label="forms.search.button_style"
				value={form.search.buttonStyle}
				onChange={(val: any) => setFormSearchValue("buttonStyle", val)}
				options={[
					{
						key: "forms.search.button_style.fill",
						value: "fill",
					},
					{
						key: "forms.search.button_style.outline",
						value: "outline",
					},
				]}
			/>
		</div>
	);
}
