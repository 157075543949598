import { getColorHexByKey } from "helpers";
import { List } from "react-bootstrap-icons";
import HeaderButton from "tsx/Components/PreviewWebsite/HeaderButton";
import type { Form } from "types";

export default function BurgerMenuLarge({
	form,
	burgerMenuClick,
}: { form: Form; burgerMenuClick?: () => void }) {
	return (
		<>
			<span
				style={{
					color: getColorHexByKey(form, form.navigation.burgerMenuButtonColor),
				}}
			>
				Menü
			</span>
			<HeaderButton
				icon={
					<List
						size={26}
						fontWeight={600}
						color={getColorHexByKey(
							form,
							form.navigation.burgerMenuButtonColor,
						)}
						stroke={getColorHexByKey(
							form,
							form.navigation.burgerMenuButtonColor,
						)}
					/>
				}
				onClick={() => burgerMenuClick?.()}
				style={{
					backgroundColor: "transparent",
					borderColor: "transparent",
				}}
			/>
		</>
	);
}
