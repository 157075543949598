import { getFormState, toCamelCase } from "helpers";
import type { CSSProperties, MouseEvent } from "react";
import { Check, ExclamationTriangle, QuestionLg } from "react-bootstrap-icons";
import type { FormInputState } from "types";

const styleDefault: CSSProperties = {
	borderRadius: "4px",
	padding: "2px 4px",
	boxSizing: "border-box",
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	justifyItems: "center",
	alignItems: "center",
	fontSize: "10px",
	lineHeight: "10px",
	position: "absolute",
	right: "0px",
	top: "0px",
	columnGap: "4px",
	cursor: "pointer",
	userSelect: "none",
	WebkitUserSelect: "none",
	msUserSelect: "none",
};

const styleWorks: CSSProperties = {
	border: "1px solid #32de84",
	backgroundColor: "#32de84",
};

const styleNworking: CSSProperties = {
	border: "1px solid #fd5c63",
	backgroundColor: "#fd5c63",
	color: "white",
};

const styleUnknown: CSSProperties = {
	border: "1px solid #fd5c63",
	backgroundColor: "#fd5c63",
	color: "white",
};

const styleUncertain: CSSProperties = {
	border: "1px solid #FEBE10",
	backgroundColor: "#FEBE10",
	color: "black",
};

export default function FormInputDeveloperBadge({
	formInputId,
}: {
	formInputId: string;
}) {
	const formInputState = (): FormInputState => {
		return getFormState(formInputId.replace("forms.", "")) ?? "unknown";
	};

	const combinedStyles = () => {
		return Object.assign(
			{},
			styleDefault,
			formInputState() === "works" ? styleWorks : {},
			formInputState() === "nworking" ? styleNworking : {},
			formInputState() === "unknown" ? styleUnknown : {},
			formInputState() === "uncertain" ? styleUncertain : {},
		);
	};

	const handleOnClick = (event: MouseEvent<HTMLSpanElement>) => {
		if (event.shiftKey) {
			navigator.clipboard.writeText(toCamelCase(formInputId));
		} else {
			navigator.clipboard.writeText(
				toCamelCase(formInputId).split(".").pop() ?? "undefined",
			);
		}
	};

	if (process.env.REACT_APP_DEVELOPER_MODE !== "true") {
		return <></>;
	}

	return (
		<span style={combinedStyles()} onClick={handleOnClick}>
			{formInputState()}
			{formInputState() === "works" && <Check />}
			{formInputState() === "nworking" && <ExclamationTriangle />}
			{formInputState() === "unknown" && <QuestionLg />}
			{formInputState() === "uncertain" && <ExclamationTriangle />}
		</span>
	);
}
