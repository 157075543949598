import { useForm } from "Providers/FormProvider";
import { DROPDOWN_OPTIONS_COLORS } from "helpers";
import React from "react";
import Dropdown from "tsx/Components/Inputs/Dropdown";
import Toggle from "tsx/Components/Inputs/Toggle";

export default function HeaderForm() {
	const { form, setFormHeaderValue } = useForm();

	return (
		<div className="form-frame">
			<Dropdown
				label="forms.header.size"
				value={form.header.size}
				onChange={(val: any) => setFormHeaderValue("size", val)}
				options={[
					{ key: "forms.header.size.small", value: "small" },
					{ key: "forms.header.size.large", value: "large" },
				]}
			/>
			<Dropdown
				form={form}
				label="forms.header.background_color"
				value={form.header.backgroundColor}
				showColorPreview
				onChange={(val: any) => setFormHeaderValue("backgroundColor", val)}
				options={DROPDOWN_OPTIONS_COLORS}
			/>
			<Dropdown
				label="forms.header.logo_position"
				value={form.header.logoPosition}
				onChange={(val: any) => setFormHeaderValue("logoPosition", val)}
				options={[
					{ key: "forms.header.logo_position.left", value: "left" },
					{ key: "forms.header.logo_position.right", value: "right" },
				]}
			/>
			<Dropdown
				label="forms.header.behavior"
				value={form.header.behavior}
				onChange={(val: any) => setFormHeaderValue("behavior", val)}
				options={[
					{
						key: "forms.header.behavior.relative",
						value: "relative",
					},
					{ key: "forms.header.behavior.fixed", value: "fixed" },
					{ key: "forms.header.behavior.sticky", value: "sticky" },
				]}
			/>
			<Dropdown
				label="forms.header.navigation_style"
				value={form.header.navigationStyle}
				onChange={(val: any) => setFormHeaderValue("navigationStyle", val)}
				options={[
					{
						key: "forms.header.navigation_style.drop_down",
						value: "drop-down",
					},
					{
						key: "forms.header.navigation_style.mega_menu",
						value: "mega-menu",
					},
					{
						key: "forms.header.navigation_style.burger_menu",
						value: "burger",
					},
				]}
			/>
			<Toggle
				label="forms.header.has_button"
				value={form.header.hasButton}
				onChange={(val) => setFormHeaderValue("hasButton", val)}
			/>
			<Dropdown
				label="forms.header.button_position"
				value={form.header.buttonPosition}
				onChange={(val: any) => setFormHeaderValue("buttonPosition", val)}
				options={[
					{ key: "forms.header.button_position.top", value: "top" },
					{
						key: "forms.header.button_position.bottom",
						value: "bottom",
					},
				]}
				disabled={
					!form.header.hasButton ||
					(form.header.navigationStyle === "burger" &&
						form.header.size === "large") ||
					form.header.size === "small"
				}
			/>
			<Dropdown
				form={form}
				label="forms.header.button_background_color"
				value={form.header.buttonBackgroundColor}
				showColorPreview
				onChange={(val: any) =>
					setFormHeaderValue("buttonBackgroundColor", val)
				}
				options={DROPDOWN_OPTIONS_COLORS.filter(
					(color) => color.key !== "color.white",
				)}
				disabled={!form.header.hasButton}
			/>
			<Dropdown
				label="forms.header.button_style"
				value={form.header.buttonStyle}
				onChange={(val: any) => setFormHeaderValue("buttonStyle", val)}
				options={[
					{
						key: "forms.header.button_style.fill",
						value: "fill",
					},
					{
						key: "forms.header.button_style.outline",
						value: "outline",
					},
				]}
				disabled={!form.header.hasButton}
			/>
			<Toggle
				label="forms.header.has_border_top"
				value={form.header.hasBorderTop}
				onChange={(val) => setFormHeaderValue("hasBorderTop", val)}
			/>
			<Dropdown
				form={form}
				label="forms.header.border_top_color"
				value={form.header.borderTopColor}
				showColorPreview
				onChange={(val: any) => setFormHeaderValue("borderTopColor", val)}
				options={DROPDOWN_OPTIONS_COLORS}
				disabled={!form.header.hasBorderTop}
			/>
		</div>
	);
}
