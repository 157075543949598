import type React from "react";
import {
	type ChangeEvent,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import "css/Components/Inputs/Dropdown.scss";
import { useForm } from "Providers/FormProvider";
import classNames from "classnames";
import { getColorHexByKey, useOutsideClick } from "helpers";
import { ChevronDown } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import type { ColorKey, Form } from "types";
import TextInput from "./TextInput";

export interface Option {
	key: string;
	value: string;
	isFileUpload?: boolean;
}

export default function Dropdown({
	form,
	label,
	placeholder,
	value,
	options,
	description,
	nullable = false,
	disableSearch = false,
	showColorPreview = false,
	disabled = false,
	handleDropdownAsButton = false,
	onChange,
}: {
	form?: Form;
	label?: string;
	placeholder?: string;
	value?: string;
	options: Option[];
	description?: string;
	nullable?: boolean;
	disableSearch?: boolean;
	showColorPreview?: boolean;
	disabled?: boolean;
	handleDropdownAsButton?: boolean;
	onChange?: (val: string) => void;
}) {
	const { t } = useTranslation();
	const wrapperRef = useRef(null);
	const { updateForm } = useForm();

	const onChangeCallback = useCallback(
		(val: string) => {
			onChange?.(val);
		},
		[onChange],
	);

	const getOptionFromValue = useCallback(
		(val: string) =>
			options.find((option) => option.value === val || t(option.key) === val) ??
			null,
		[options, t],
	);

	const [selectedOption, setSelectedOption] = useState<Option | null>(
		getOptionFromValue(value ?? ""),
	);
	const [lastValidValue, setLastValidValue] = useState<string>(
		t(selectedOption?.key ?? "") ?? "",
	);
	const [search, setSearch] = useState<string>(lastValidValue);
	const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
	const [isValid, setIsValid] = useState<boolean>(true);
	const [showAllOptions, setShowAllOptions] = useState<boolean>(true);

	const filteredOptions = useMemo(
		() =>
			options.filter(
				(option) =>
					option.value.includes(search) ||
					t(option.key).includes(search) ||
					showAllOptions,
			),
		[options, search, showAllOptions, t],
	);

	const handleOnSearchChange = (search: string) => {
		setSearch(search);
		setShowAllOptions(false);
	};

	const handleOptionClick = (e: React.MouseEvent, option: Option) => {
		e.preventDefault();
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
		setSelectedOption(option);
		onChangeCallback(option.value);
		setSearch(t(option.key));
		setIsCollapsed(false);
		setShowAllOptions(true);
	};

	const handleClearClick = useCallback(() => {
		setSelectedOption(null);
		onChange?.("");
		setSearch("");
		setIsCollapsed(false);
		setShowAllOptions(true);
	}, [onChange]);

	const handleOnBlur = () => {
		setIsCollapsed(false);

		if (getOptionFromValue(search) === null) {
			setSearch(lastValidValue);
			setShowAllOptions(true);
		}
	};

	useEffect(() => {
		const optionFound = getOptionFromValue(search);

		if (optionFound) {
			setIsValid(true);
			setLastValidValue(search);
			setSelectedOption(optionFound);
			//onChangeCallback(optionFound.value); // Removed due rerender loop
			if (handleDropdownAsButton) {
				handleClearClick();
			}
		} else {
			setIsValid(false);
		}
	}, [search, getOptionFromValue, handleClearClick, handleDropdownAsButton]);

	// useEffect(() => {
	// 	if (filteredOptions.length === 0) {
	// 		setIsValid(false);
	// 	} else {
	// 		setIsValid(true);
	// 	}
	// }, [filteredOptions]);

	const onReaderLoad = (event: ProgressEvent<FileReader>) => {
		if (typeof event.target?.result === "string") {
			const object = JSON.parse(event.target?.result);
			updateForm(object);
		}
	};

	const onFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];

		if (file) {
			const reader = new FileReader();
			reader.onload = onReaderLoad;
			reader.readAsText(file);
		}
	};

	useOutsideClick(wrapperRef, () => setIsCollapsed(false));

	return (
		<div
			ref={wrapperRef}
			className={classNames({
				"dropdown-frame": true,
				"real-dropdown": disableSearch,
			})}
		>
			{!disableSearch && (
				<TextInput
					label={label}
					value={search}
					isValid={isValid}
					description={description}
					disabled={disabled}
					onChange={handleOnSearchChange}
					onFocus={() => setIsCollapsed(true)}
					onBlur={() => handleOnBlur()}
					onClick={() => setIsCollapsed(true)}
				/>
			)}
			{disableSearch && (
				<span
					className="real-dropdown-btn"
					onClick={() => setIsCollapsed((prev) => !prev)}
					onBlur={() => handleOnBlur()}
					onFocus={() => setIsCollapsed(true)}
				>
					{label}
					<ChevronDown />
				</span>
			)}
			{isCollapsed && (
				<span className="dropdown">
					{filteredOptions.map((option: Option) => {
						if (option.isFileUpload !== true) {
							return (
								<span
									className="option-label"
									key={option.key}
									onClick={(e) => handleOptionClick(e, option)}
									onMouseDown={(e) => e.preventDefault()}
								>
									{showColorPreview && form && (
										<ColorPreview
											form={form}
											colorKey={option.value as ColorKey}
										/>
									)}
									{t(option.key)}
								</span>
							);
						}
						return (
							<label htmlFor="upload" key={`${option.key}-label`}>
								<span
									className="option-label"
									key={`${option.key}-label-span`}
									onMouseDown={(e) => e.preventDefault()}
								>
									{t(option.key)}
								</span>
								<input
									key={`${option.key}-label-input`}
									type="file"
									id="upload"
									accept="application/JSON"
									onChange={onFileUpload}
								/>
							</label>
						);
					})}
				</span>
			)}
			{form && selectedOption && (
				<ColorPreview
					form={form}
					colorKey={selectedOption.value as ColorKey}
					className={["input"]}
				/>
			)}
			{search.length > 0 && nullable && (
				<span className="clear" onClick={() => handleClearClick()}>
					X
				</span>
			)}
		</div>
	);
}

function ColorPreview({
	form,
	colorKey,
	className = [],
}: {
	form: Form;
	colorKey: ColorKey;
	className?: string[] | { [key: string]: boolean };
}) {
	return (
		<span
			className={classNames("color-preview", className)}
			style={{
				backgroundColor: getColorHexByKey(form, colorKey),
			}}
		/>
	);
}
