import React from "react";
import "css/Components/Inputs/Toggle.scss";
import { useTranslation } from "react-i18next";
import FormInputDeveloperBadge from "../Developer/FormInputDeveloperBadge";

export default function Toggle({
	label,
	value,
	onChange,
}: {
	label?: string;
	value: boolean;
	onChange: (val: boolean) => void;
}) {
	const { t } = useTranslation();

	return (
		<div className="switch-frame">
			<label className="switch">
				<input
					type="checkbox"
					checked={value}
					onChange={(e) => onChange(e.target.checked)}
				/>
				<span className="slider" />
			</label>
			{label && <span className="label">{t(label ?? "")}</span>}
			<FormInputDeveloperBadge formInputId={label ?? "undefined"} />
		</div>
	);
}
