import { useForm } from "Providers/FormProvider";
import { getColorHexByKey, getContrastColorHexByKey } from "helpers";
import React from "react";
import { ChevronRight } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

export default function InterestingPagesContainer() {
	const { t } = useTranslation();
	const { form } = useForm();

	return (
		<div className="interesting-pages">
			<h2 className="title">{t("preview.interesting_pages.title")}</h2>
			<div className="link-boxes">
				{[0, 1, 2, 3].map((index) => (
					<div className="link-box" key={index}>
						<span className="link-title">{t("preview.navbar.sitemap")}</span>
						<span className="link-submenus">
							{index === 1 && (
								<>
									<span>
										{t("preview.navbar.submenu_item")}
										<ChevronRight />
									</span>
									<span>
										{t("preview.navbar.submenu_item")}
										<ChevronRight />
									</span>
								</>
							)}
						</span>
						<span className="link-button">
							<a
								href="#learn_more"
								style={{
									backgroundColor: getColorHexByKey(
										form,
										form.general.colorPrimary,
									),
									color: getContrastColorHexByKey(
										form,
										form.general.colorPrimary,
									),
								}}
							>
								{t("preview.interesting_pages.learn_more")}
							</a>
						</span>
					</div>
				))}
			</div>
		</div>
	);
}
