import { useForm } from "Providers/FormProvider";
import { DROPDOWN_OPTIONS_COLORS } from "helpers";
import React from "react";
import Dropdown from "tsx/Components/Inputs/Dropdown";
import Toggle from "tsx/Components/Inputs/Toggle";

export default function SubpagesForm() {
	const { form, setFormSubpagesValue } = useForm();

	return (
		<div className="form-frame">
			<Toggle
				label="forms.subpages.has_header_image"
				value={form.subpages.hasHeaderImage}
				onChange={(val) => setFormSubpagesValue("hasHeaderImage", val)}
			/>
			<Toggle
				label="forms.subpages.has_breadcrumb_menu"
				value={form.subpages.hasBreadcrumbMenu}
				onChange={(val) => setFormSubpagesValue("hasBreadcrumbMenu", val)}
			/>
			<Toggle
				label="forms.subpages.has_breadcrumb_menu_on_subpages"
				value={form.subpages.hasBreadcrumbMenuOnSubpages}
				onChange={(val) =>
					setFormSubpagesValue("hasBreadcrumbMenuOnSubpages", val)
				}
			/>
			<Toggle
				label="forms.subpages.has_page_title"
				value={form.subpages.hasPageTitle}
				onChange={(val) => setFormSubpagesValue("hasPageTitle", val)}
			/>
			<Toggle
				label="forms.subpages.has_page_title_on_subpages"
				value={form.subpages.hasPageTitleOnSubpages}
				onChange={(val) => setFormSubpagesValue("hasPageTitleOnSubpages", val)}
			/>
			<Dropdown
				form={form}
				label="forms.subpages.page_title_background_color"
				value={form.subpages.pageTitleBackgroundColor}
				showColorPreview
				onChange={(val: any) =>
					setFormSubpagesValue("pageTitleBackgroundColor", val)
				}
				options={DROPDOWN_OPTIONS_COLORS}
			/>
			<Dropdown
				form={form}
				label="forms.subpages.breadcrumb_background_color"
				value={form.subpages.breadcrumbBackgroundColor}
				showColorPreview
				onChange={(val: any) =>
					setFormSubpagesValue("breadcrumbBackgroundColor", val)
				}
				options={DROPDOWN_OPTIONS_COLORS}
			/>
		</div>
	);
}
