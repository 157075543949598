import React, { type CSSProperties } from "react";
import "css/PreviewWebsite/Container/HeaderContainer.scss";
import { useForm } from "Providers/FormProvider";
import classNames from "classnames";
import { getColorHexByKey } from "helpers";
import { useTranslation } from "react-i18next";
import { usePreviewWebsiteHandler } from "tsx/PreviewWebsite/PreviewWebsite";

const PreviewLinks: string[] = [
	"preview.links.startpage",
	"preview.links.news",
	"preview.links.contact",
	"preview.links.impress",
	"preview.links.privacy",
	"preview.links.accessibility",
	"preview.links.sitemap",
];

export default function QuicklinksContrainer({
	style,
}: {
	style?: CSSProperties;
}) {
	const { t } = useTranslation();
	const { form } = useForm();
	const { setLocation } = usePreviewWebsiteHandler();

	return (
		<div
			className="quicklinks"
			style={{
				...{
					backgroundColor: getColorHexByKey(form, form.header.backgroundColor),
					color: getColorHexByKey(form, form.navigation.topNavigationColor),
					borderLeftColor: getColorHexByKey(
						form,
						form.navigation.useDividingLines
							? form.navigation.topNavigationColor
							: form.header.backgroundColor,
					),
					justifyContent: form.navigation.topNavigationPosition,
				},
				...style,
			}}
		>
			{PreviewLinks.map((link, index) => (
				<span
					key={link}
					className={classNames({ active: index === 0 })}
					style={{
						borderLeftColor: getColorHexByKey(
							form,
							form.navigation.useDividingLines
								? form.navigation.topNavigationColor
								: form.header.backgroundColor,
						),
					}}
					onClick={() => setLocation(index === 0 ? "mainpage" : "subpage")}
				>
					{t(link)}
				</span>
			))}
		</div>
	);
}
