import React from "react";
import "css/Frames/FormFrame.scss";
import { usePageLocation } from "Providers/PageLocationProvider";
import FooterForm from "tsx/Forms/FooterForm";
import GeneralForm from "tsx/Forms/GeneralForm";
import HeaderForm from "tsx/Forms/HeaderForm";
import LayoutForm from "tsx/Forms/LayoutForm";
import NavigationForm from "tsx/Forms/NavigationForm";
import SearchForm from "tsx/Forms/SearchForm";
import SubpagesForm from "tsx/Forms/SubpagesForm";
import WeatherForm from "tsx/Forms/WeatherForm";

export default function FormFrame() {
	const { currentLocation } = usePageLocation();

	return (
		<>
			{currentLocation === "general" && <GeneralForm />}
			{currentLocation === "layout" && <LayoutForm />}
			{currentLocation === "header" && <HeaderForm />}
			{currentLocation === "navigation" && <NavigationForm />}
			{currentLocation === "search" && <SearchForm />}
			{currentLocation === "weather" && <WeatherForm />}
			{currentLocation === "footer" && <FooterForm />}
			{currentLocation === "subpages" && <SubpagesForm />}
		</>
	);
}
