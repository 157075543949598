import { useForm } from "Providers/FormProvider";
import { DROPDOWN_OPTIONS_COLORS } from "helpers";
import React from "react";
import Dropdown from "tsx/Components/Inputs/Dropdown";
import Toggle from "tsx/Components/Inputs/Toggle";

export default function WeatherForm() {
	const { form, setFormWeatherValue } = useForm();

	return (
		<div className="form-frame">
			<Toggle
				label="forms.weather.has_weather_widget"
				value={form.weather.hasWeatherWidget}
				onChange={(val) => setFormWeatherValue("hasWeatherWidget", val)}
			/>
			<Dropdown
				form={form}
				label="forms.weather.button_color"
				value={form.weather.buttonColor}
				showColorPreview
				onChange={(val: any) => setFormWeatherValue("buttonColor", val)}
				options={DROPDOWN_OPTIONS_COLORS.filter(
					(color) => color.key !== "color.white",
				)}
				disabled={!form.weather.hasWeatherWidget}
			/>
			<Dropdown
				label="forms.weather.button_style"
				value={form.weather.buttonStyle}
				onChange={(val: any) => setFormWeatherValue("buttonStyle", val)}
				disabled={!form.weather.hasWeatherWidget}
				options={[
					{
						key: "forms.weather.button_style.fill",
						value: "fill",
					},
					{
						key: "forms.weather.button_style.outline",
						value: "outline",
					},
				]}
			/>
		</div>
	);
}
