import { useForm } from "Providers/FormProvider";
import { DROPDOWN_OPTIONS_COLORS } from "helpers";
import React from "react";
import ColorPicker from "tsx/Components/Inputs/ColorPicker";
import Dropdown from "tsx/Components/Inputs/Dropdown";
import Toggle from "tsx/Components/Inputs/Toggle";

export default function LayoutForm() {
	const { form, setFormLayoutValue } = useForm();

	return (
		<div className="form-frame">
			<Dropdown
				label="forms.layout.layout"
				value={form.layout.layout}
				onChange={(val: any) => setFormLayoutValue("layout", val)}
				options={[
					{ key: "forms.layout.layout.wide", value: "wide" },
					{ key: "forms.layout.layout.boxed", value: "boxed" },
					{ key: "forms.layout.layout.framed", value: "framed" },
				]}
			/>
			<Toggle
				label="forms.layout.fake_loader"
				value={form.layout.fakeLoader}
				onChange={(val: any) => setFormLayoutValue("fakeLoader", val)}
			/>
			<Dropdown
				form={form}
				label="forms.layout.fake_loader_color"
				value={form.layout.fakeLoaderColor}
				showColorPreview
				onChange={(val: any) => setFormLayoutValue("fakeLoaderColor", val)}
				options={DROPDOWN_OPTIONS_COLORS}
				disabled={!form.layout.fakeLoader}
			/>
			<ColorPicker
				label="forms.layout.body_background_color"
				value={form.layout.bodyBackgroundColor}
				onChange={(val: string) =>
					setFormLayoutValue("bodyBackgroundColor", val)
				}
			/>
		</div>
	);
}
