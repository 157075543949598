import React from "react";

export default function LoadingSpinner({ color }: { color: string }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 100 100"
			preserveAspectRatio="xMidYMid"
			display="block"
			style={{ margin: "auto", background: "none", shapeRendering: "auto" }}
		>
			<title>Loading Spinner</title>
			<circle
				cx="50"
				cy="50"
				r="32"
				strokeWidth="10"
				stroke={color}
				strokeDasharray="80"
				fill="none"
				strokeLinecap="round"
			>
				<animateTransform
					attributeName="transform"
					type="rotate"
					repeatCount="indefinite"
					dur="1s"
					values="0 50 50;360 50 50"
					keyTimes="0;1"
				/>
			</circle>
		</svg>
	);
}
