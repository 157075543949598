import { useForm } from "Providers/FormProvider";
import "css/Components/PreviewWebsite/IconNavigation.scss";
import { getColorHexByKey, getContrastColorHexByKey } from "helpers";
import { Check } from "react-bootstrap-icons";

export default function IconNavigation() {
	const { form } = useForm();

	return (
		<div className="icon-navigation">
			<span
				style={{
					backgroundColor: getColorHexByKey(
						form,
						form.navigation.iconNavigationBackgroundColor,
					),
				}}
			>
				<span
					style={{
						color: getContrastColorHexByKey(
							form,
							form.navigation.iconNavigationBackgroundColor,
						),
					}}
				>
					Test
				</span>
				<Check
					color={getContrastColorHexByKey(
						form,
						form.navigation.iconNavigationBackgroundColor,
					)}
					size={40}
				/>
			</span>
		</div>
	);
}
