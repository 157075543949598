import React from "react";
import Layout from "./tsx/Layout";
import { PageLocationProvider } from "./Providers/PageLocationProvider";
import { FormProvider } from "Providers/FormProvider";

function App() {
    return (
        <PageLocationProvider>
            <FormProvider>
                <Layout />
            </FormProvider>
        </PageLocationProvider>
    );
}

export default App;
