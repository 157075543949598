import React from "react";
import "css/PreviewWebsite/Container/HeaderContainer.scss";
import { useForm } from "Providers/FormProvider";
import classNames from "classnames";
import { getColorHexByKey } from "helpers";
import { Check2 } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import HeaderButton from "tsx/Components/PreviewWebsite/HeaderButton";
import Logo from "tsx/Components/PreviewWebsite/Logo";
import SearchInput from "tsx/Components/PreviewWebsite/SearchInput";
import QuicklinksContrainer from "./Header/QuicklinksContrainer";

export default function HeaderContainer({
	toggleSearchbarVisible = () => {},
	toggleBurgerMenuVisible = () => {},
}: {
	toggleSearchbarVisible?: () => void;
	toggleBurgerMenuVisible?: () => void;
}) {
	const { t } = useTranslation();
	const { form } = useForm();

	return (
		<div
			className={classNames("header", {
				"border-top": form.header.hasBorderTop,
				fixed: form.header.behavior === "fixed",
				sticky: form.header.behavior === "sticky",
				framed:
					form.header.behavior !== "fixed" && form.layout.layout === "framed",
				boxed:
					form.layout.layout === "boxed" && form.header.behavior !== "fixed",
				"outer-shadow": true,
			})}
			style={{
				backgroundColor: getColorHexByKey(form, form.header.backgroundColor),
				borderTopColor: getColorHexByKey(form, form.header.borderTopColor),
			}}
		>
			{form.navigation.topNavigation && <QuicklinksContrainer />}
			{form.header.size === "large" && (
				<div
					className={classNames({
						"header-inner": true,
						right: form.header.logoPosition === "right",
					})}
				>
					<Logo disableAutoStyle />
					<div className="buttons">
						{form.header.hasButton && form.header.buttonPosition === "top" && (
							<HeaderButton
								icon={<Check2 fontWeight={"bold"} />}
								label="Test BTN"
							/>
						)}
						{form.search.style !== "bar" && (
							<HeaderButton
								label={t("search")}
								isSearchButton
								onClick={() => toggleSearchbarVisible?.()}
							/>
						)}
						{form.search.style === "bar" && <SearchInput />}
					</div>
				</div>
			)}
		</div>
	);
}
