import { useForm } from "Providers/FormProvider";
import { DROPDOWN_OPTIONS_COLORS } from "helpers";
import React from "react";
import Dropdown from "tsx/Components/Inputs/Dropdown";
import Toggle from "tsx/Components/Inputs/Toggle";

export default function NavigationForm() {
	const { form, setFormNavigationValue } = useForm();

	return (
		<div className="form-frame">
			<Dropdown
				label="forms.navigation.behavior"
				value={form.navigation.behavior}
				onChange={(val: any) => setFormNavigationValue("behavior", val)}
				options={[
					{
						key: "forms.navigation.behavior.relative",
						value: "relative",
					},
					{
						key: "forms.navigation.behavior.sticky",
						value: "sticky",
					},
				]}
				disabled={
					!(
						form.header.size === "large" &&
						form.header.behavior === "relative" &&
						form.layout.layout === "wide"
					)
				}
			/>
			<Dropdown
				form={form}
				label="forms.navigation.menu_background_color"
				value={form.navigation.menuBackgroundColor}
				showColorPreview
				onChange={(val: any) =>
					setFormNavigationValue("menuBackgroundColor", val)
				}
				options={DROPDOWN_OPTIONS_COLORS}
			/>
			<Dropdown
				label="forms.navigation.font_family"
				value={form.navigation.fontFamily}
				onChange={(val: any) => setFormNavigationValue("fontFamily", val)}
				options={[
					{ key: "forms.navigation.font_family.body", value: "body" },
					{
						key: "forms.navigation.font_family.title",
						value: "title",
					},
				]}
			/>
			<Dropdown
				form={form}
				label="forms.navigation.menu_font_color"
				value={form.navigation.menuFontColor}
				showColorPreview
				onChange={(val: any) => setFormNavigationValue("menuFontColor", val)}
				options={DROPDOWN_OPTIONS_COLORS}
			/>
			<Dropdown
				form={form}
				label="forms.navigation.burger_menu_button_color"
				value={form.navigation.burgerMenuButtonColor}
				showColorPreview
				onChange={(val: any) =>
					setFormNavigationValue("burgerMenuButtonColor", val)
				}
				disabled={!(form.header.navigationStyle === "burger")}
				options={DROPDOWN_OPTIONS_COLORS}
			/>
			<Toggle
				label="forms.navigation.chevron_main_nav"
				value={form.navigation.chevronMainNav}
				onChange={(val: any) => setFormNavigationValue("chevronMainNav", val)}
			/>
			<Toggle
				label="forms.navigation.top_navigation"
				value={form.navigation.topNavigation}
				onChange={(val: any) => setFormNavigationValue("topNavigation", val)}
			/>
			<Dropdown
				label="forms.navigation.top_navigation_position"
				value={form.navigation.topNavigationPosition}
				onChange={(val: any) =>
					setFormNavigationValue("topNavigationPosition", val)
				}
				options={[
					{
						key: "forms.navigation.top_navigation_position.flex_start",
						value: "flex-start",
					},
					{
						key: "forms.navigation.top_navigation_position.center",
						value: "center",
					},
					{
						key: "forms.navigation.top_navigation_position.flex_end",
						value: "flex-end",
					},
				]}
			/>
			<Toggle
				label="forms.navigation.top_navigation_mobile"
				value={form.navigation.topNavigationMobile}
				onChange={(val: any) =>
					setFormNavigationValue("topNavigationMobile", val)
				}
			/>
			<Dropdown
				form={form}
				label="forms.navigation.top_navigation_color"
				value={form.navigation.topNavigationColor}
				showColorPreview
				onChange={(val: any) =>
					setFormNavigationValue("topNavigationColor", val)
				}
				options={[
					...DROPDOWN_OPTIONS_COLORS,
					{ key: "color.body", value: "body" },
				]}
				disabled={!form.navigation.topNavigation}
			/>
			<Toggle
				label="forms.navigation.use_dividing_lines"
				value={form.navigation.useDividingLines}
				onChange={(val: any) => setFormNavigationValue("useDividingLines", val)}
			/>
			<Toggle
				label="forms.navigation.icon_navigation"
				value={form.navigation.iconNavigation}
				onChange={(val: any) => setFormNavigationValue("iconNavigation", val)}
			/>
			<Dropdown
				form={form}
				label="forms.navigation.icon_navigation_background_color"
				value={form.navigation.iconNavigationBackgroundColor}
				showColorPreview
				onChange={(val: any) =>
					setFormNavigationValue("iconNavigationBackgroundColor", val)
				}
				options={DROPDOWN_OPTIONS_COLORS}
				disabled={!form.navigation.iconNavigation}
			/>
		</div>
	);
}
