import React from "react";
import "css/PreviewWebsite/PreviewWebsite.scss";
import { useTranslation } from "react-i18next";

export default function EventsContainer() {
	const { t } = useTranslation();

	return (
		<div className="events outer-shadow">
			<h2>{t("preview.events.title")}</h2>
			<div className="cards">
				{[0, 1, 2, 3].map((index) => (
					<div className="card" key={index}>
						<span className="card-image">
							<div className="card-calendar">
								<span className="day">27</span>
								<span className="month">JUL</span>
							</div>
						</span>
						<span className="card-title">{t("preview.events.news.title")}</span>
						<span className="card-content">
							Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
							nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
							erat, sed diam voluptua. At vero eos et…
						</span>
					</div>
				))}
			</div>
		</div>
	);
}
