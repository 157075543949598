import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import de from "./lang/de.json";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";

i18next.use(initReactI18next).init({
    lng: "de",
    interpolation: {
        escapeValue: false,
    },
    react: {
        useSuspense: false,
    },
    resources: {
        de: {
            translation: Object.fromEntries(
                Object.entries(de).map(([key, value]) => [
                    key,
                    value.replace(/:([^" ]+)/g, "{{$1}}"),
                ])
            ),
        },
    },
});

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route index element={<Navigate to="/general" replace />} />
                <Route index path=":subsite" element={<App />} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
