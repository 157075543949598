import React from "react";
import "css/Components/Inputs/ColorPicker.scss";
import { useTranslation } from "react-i18next";
import FormInputDeveloperBadge from "../Developer/FormInputDeveloperBadge";

export default function ColorPicker({
	label,
	value,
	onChange,
}: {
	label?: string;
	value?: string;
	onChange?: (val: string) => void;
}) {
	const { t } = useTranslation();

	return (
		<div className="colorPicker-frame">
			<span className="label">
				{t(label ?? "components.inputs.color_picker")}
				<FormInputDeveloperBadge formInputId={label ?? "undefined"} />
			</span>
			<label className="colorPicker">
				<input
					type="color"
					value={value}
					onChange={(e) => onChange?.(e.target.value)}
				/>
				<span className="color" style={{ backgroundColor: value }} />
				<span className="value">{value?.toUpperCase()}</span>
			</label>
		</div>
	);
}
