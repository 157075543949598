import React from "react";
import "css/Components/PreviewWebsite/SearchInput.scss";
import classNames from "classnames";
import { Search } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

export default function SearchInput() {
	const { t } = useTranslation();

	return (
		<div className={classNames({ "search-input-wraper": true })}>
			<input type="text" placeholder={t("preview.header.searchbar")} />
			<Search />
		</div>
	);
}
