import React from "react";
import "css/PreviewWebsite/PreviewWebsite.scss";
import { useForm } from "Providers/FormProvider";
import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";

export default function HeadimageContainer() {
	const { t } = useTranslation();
	const { form } = useForm();

	return (
		<div
			className={classNames({
				headimage: true,
				boxed: form.layout.layout === "boxed",
				framed: form.layout.layout === "framed",
				"margin-top":
					form.header.behavior === "fixed" && form.layout.layout === "framed",
				"outer-shadow": true,
			})}
		>
			<div>
				<span style={{ fontWeight: 600, fontSize: 18 }}>
					<Trans i18nKey="preview.headimage.title" />
				</span>
				<span style={{ fontSize: 14 }}>{t("preview.headimage.subtitle")}</span>
			</div>
		</div>
	);
}
