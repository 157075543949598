import React from "react";
import "css/Layout.scss";
import { useForm } from "Providers/FormProvider";
import { useTranslation } from "react-i18next";
import logo from "svg/logo.svg";
import SitePreviewFrame from "tsx/Frames/SitePreviewFrame";
import Dropdown, { type Option } from "./Components/Inputs/Dropdown";
import FormFrame from "./Frames/FormFrame";
import TabsFrame from "./Frames/TabsFrame";
import LayoutTabs from "./Layout/LayoutTabs";

const OptionTabs: Option[] = [
	{
		key: "layout.header.option.clear_inputs",
		value: "clear_inputs",
	},
	{
		key: "layout.header.option.clear_cache",
		value: "clear_cache",
	},
	{
		key: "layout.header.option.config_down",
		value: "config_down",
	},
	{
		key: "layout.header.option.config_up",
		value: "config_up",
		isFileUpload: true,
	},
];

export default function Layout() {
	const { t } = useTranslation();
	const { clearFormCache, resetAllForms, downloadFormAsFile } = useForm();

	const handleOptionClick = (val: string) => {
		switch (val) {
			case "clear_inputs":
				resetAllForms();
				break;
			case "clear_cache":
				clearFormCache();
				break;
			case "config_down":
				console.log("Requested Config Download");
				downloadFormAsFile();
				break;
			case "config_up":
				break;

			default:
				break;
		}
	};

	return (
		<div className="layout">
			<div className="header">
				<img src={logo} className="logo" alt="logo" />
				<LayoutTabs />
				<Dropdown
					options={OptionTabs}
					disableSearch
					label={t("options")}
					onChange={handleOptionClick}
					handleDropdownAsButton
				/>
			</div>
			<div className="layout-grid">
				{false && <TabsFrame />}
				<FormFrame />
				<SitePreviewFrame />
			</div>
		</div>
	);
}
