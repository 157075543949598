import React from "react";
import { ArrowUpCircle, Printer } from "react-bootstrap-icons";
import "css/PreviewWebsite/Container/FooterContainer.scss";
import { useForm } from "Providers/FormProvider";
import classNames from "classnames";
import { getColorHexByKey } from "helpers";
import { useTranslation } from "react-i18next";
import { usePreviewWebsiteHandler } from "../PreviewWebsite";

const PreviewLinks: string[] = [
	"preview.links.startpage",
	"preview.links.news",
	"preview.links.contact",
	"preview.links.impress",
	"preview.links.privacy",
	"preview.links.accessibility",
	"preview.links.sitemap",
];

export default function FooterContainer({
	onScrollToTop,
}: {
	onScrollToTop?: () => void;
}) {
	const { t } = useTranslation();
	const { form } = useForm();
	const { setLocation } = usePreviewWebsiteHandler();

	return (
		<div
			className={classNames({
				footer: true,
				boxed: form.layout.layout === "boxed",
				framed: form.layout.layout === "framed",
			})}
			style={{
				backgroundColor: getColorHexByKey(form, form.footer.backgroundColor),
			}}
		>
			{form.footer.showCopyright && (
				<div
					className="copyright"
					style={{
						color: getColorHexByKey(form, form.footer.fontColor),
					}}
				>
					{" "}
					{t("preview.footer.copyright")}
				</div>
			)}
			<div
				className="quicklinks"
				style={{ color: getColorHexByKey(form, form.footer.fontColor) }}
			>
				{PreviewLinks.map((link, index) => (
					<span
						key={link}
						className={classNames({ active: index === 0 })}
						style={{
							borderLeftColor: getColorHexByKey(
								form,
								form.footer.useDividingLines
									? form.footer.fontColor
									: form.footer.backgroundColor,
							),
						}}
						onClick={() => setLocation(index === 0 ? "mainpage" : "subpage")}
					>
						{t(link)}
					</span>
				))}
			</div>

			<div className="icons">
				{form.footer.showPrintIcon && (
					<Printer
						style={{
							color: getColorHexByKey(form, form.footer.fontColor),
						}}
						size={24}
					/>
				)}
				{form.footer.showBackToTopIcon && (
					<ArrowUpCircle
						style={{
							color: getColorHexByKey(form, form.footer.fontColor),
						}}
						size={24}
						onClick={() => onScrollToTop?.()}
					/>
				)}
			</div>
		</div>
	);
}
