import React from "react";
import "css/Layout/LayoutTabs.scss";
import { Configuration } from "Configuration";
import { usePageLocation } from "Providers/PageLocationProvider";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

export default function LayoutTabs() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { tabs } = Configuration;

	const { currentLocation } = usePageLocation();

	return (
		<div className="layout-tabs">
			{tabs.map((tab) => (
				<span
					key={tab.id}
					className={classNames({
						focused: tab.id === currentLocation,
					})}
					onClick={() => navigate(`/${tab.id}`)}
				>
					{t(tab.title)}
				</span>
			))}
		</div>
	);
}
