import type { Configuration as ConfigurationInterface } from "./types";

export const Configuration: ConfigurationInterface = {
    defaultSubsiteId: "main",
    tabs: [
        {
            id: "general",
            title: "layout.tabs.general",
            subsiteTitle: "layout.grid.tabframe.configurate_general_title",
            subsiteSubTitle: "missing",
        },
        {
            id: "layout",
            title: "layout.tabs.layout",
            subsiteTitle: "layout.grid.tabframe.configurate_layout_title",
            subsiteSubTitle: "missing",
        },
        {
            id: "header",
            title: "layout.tabs.header",
            subsiteTitle: "layout.grid.tabframe.configurate_header_title",
            subsiteSubTitle: "missing",
        },
        {
            id: "navigation",
            title: "layout.tabs.navigation",
            subsiteTitle: "layout.grid.tabframe.configurate_navigation_title",
            subsiteSubTitle: "missing",
        },
        {
            id: "search",
            title: "layout.tabs.search",
            subsiteTitle: "layout.grid.tabframe.configurate_searchbar_title",
            subsiteSubTitle:
                "layout.grid.tabframe.configurate_searchbar_subtitle",
        },
        {
            id: "weather",
            title: "layout.tabs.weather",
            subsiteTitle: "layout.grid.tabframe.configurate_weather_title",
            subsiteSubTitle: "missing",
        },
        {
            id: "footer",
            title: "layout.tabs.footer",
            subsiteTitle: "layout.grid.tabframe.configurate_footer_title",
            subsiteSubTitle: "missing",
        },
        {
            id: "subpages",
            title: "layout.tabs.subpages",
            subsiteTitle: "layout.grid.tabframe.configurate_subpages_title",
            subsiteSubTitle: "missing",
        },
    ],
    defaultColors: {
        primary: "#007bff",
        secondary: "#6c757d",
        success: "#198754",
        info: "#0dcaf0",
        warning: "#ffc107",
        danger: "#dc3545",
        light: "#f8f9fa",
        dark: "#212529",
        white: "#ffffff",
        body: "#000000"
    },
    defaultFormData: {
        general: {
            institutionName: "",
            domainName: "",
            domainExists: false,
            fontFamilyBody: "",
            fontFamilyTitle: "",
            colorPrimary: "#007bff",
            colorSecondary: "#6c757d",
        },
        layout: {
            layout: "wide",
            fakeLoader: false,
            fakeLoaderColor: "primary",
            bodyBackgroundColor: "#ffffff",
        },
        header: {
            logoPosition: "left",
            backgroundColor: "white",
            size: "large",
            behavior: "relative",
            navigationStyle: "drop-down",
            hasButton: true,
            buttonPosition: "top",
            buttonBackgroundColor: "secondary",
            buttonStyle: "fill",
            hasBorderTop: true,
            borderTopColor: "secondary",
        },
        navigation: {
            behavior: "relative",
            menuBackgroundColor: "primary",
            fontFamily: "body",
            menuFontColor: "white",
            burgerMenuButtonColor: "white",
            chevronMainNav: true,
            topNavigation: true,
            topNavigationPosition: "flex-start",
            topNavigationMobile: true,
            topNavigationColor: "dark",
            useDividingLines: true,
            iconNavigation: true,
            iconNavigationBackgroundColor: "primary",
        },
        search: {
            style: "collapse",
            searchbarBackgroundColor: "light",
            buttonBackgroundColor: "secondary",
            buttonStyle: "fill",
        },
        weather: {
            hasWeatherWidget: true,
            buttonColor: "white",
            buttonStyle: "outline",
        },
        footer: {
            fontColor: "white",
            backgroundColor: "primary",
            showCopyright: true,
            showPrintIcon: true,
            showBackToTopIcon: true,
            useDividingLines: true,
        },
        subpages: {
            hasHeaderImage: true,
            hasBreadcrumbMenu: false,
            hasBreadcrumbMenuOnSubpages: false,
            hasPageTitle: false,
            hasPageTitleOnSubpages: true,
            pageTitleBackgroundColor: "secondary",
            breadcrumbBackgroundColor: "light",
        },
    },
    statesFormData: {
        general: {
            institutionName: "works",
            domainName: "works",
            domainExists: "works",
            fontFamilyBody: "unknown",
            fontFamilyTitle: "unknown",
            colorPrimary: "works",
            colorSecondary: "works",
        },
        layout: {
            layout: "works",
            fakeLoader: "works",
            fakeLoaderColor: "works",
            bodyBackgroundColor: "works",
        },
        header: {
            logoPosition: "works",
            backgroundColor: "works",
            size: "works",
            behavior: "works",
            navigationStyle: "works",
            hasButton: "works",
            buttonPosition: "works",
            buttonBackgroundColor: "works",
            buttonStyle: "works",
            hasBorderTop: "works",
            borderTopColor: "works",
        },
        navigation: {
            behavior: "works",
            menuBackgroundColor: "works",
            fontFamily: "uncertain",
            menuFontColor: "works",
            burgerMenuButtonColor: "works",
            chevronMainNav: "works",
            topNavigation: "works",
            topNavigationPosition: "works",
            topNavigationMobile: "unknown",
            topNavigationColor: "works",
            useDividingLines: "works",
            iconNavigation: "unknown",
            iconNavigationBackgroundColor: "unknown",
        },
        search: {
            style: "works",
            searchbarBackgroundColor: "works",
            buttonBackgroundColor: "works",
            buttonStyle: "works",
        },
        weather: {
            hasWeatherWidget: "works",
            buttonColor: "works",
            buttonStyle: "works",
        },
        footer: {
            fontColor: "works",
            backgroundColor: "works",
            showCopyright: "works",
            showPrintIcon: "works",
            showBackToTopIcon: "works",
            useDividingLines: "works",
        },
        subpages: {
            hasHeaderImage: "uncertain",
            hasBreadcrumbMenu: "works",
            hasBreadcrumbMenuOnSubpages: "works",
            hasPageTitle: "works",
            hasPageTitleOnSubpages: "works",
            pageTitleBackgroundColor: "works",
            breadcrumbBackgroundColor: "works",
        },
    },
    formKeysConversionMatrix: {
        general: {
            institutionName: undefined,
            domainName: undefined,
            domainExists: undefined,
            fontFamilyBody: undefined,
            fontFamilyTitle: undefined,
            colorPrimary: undefined,
            colorSecondary: undefined,
        },
        layout: {
            layout: "plugin.tx_nwsgovgo.page.page-layout",
            fakeLoader: "plugin.tx_nwsgovgo.page.page-transition",
            fakeLoaderColor: "plugin.tx_nwsgovgo.page.page-transition-color",
            bodyBackgroundColor: "plugin.tx_nwsgovgo.page.body-bg",
        },
        header: {
            logoPosition: "plugin.tx_nwsgovgo.header.header-logo",
            backgroundColor: "plugin.tx_nwsgovgo.header.header-bg",
            size: "plugin.tx_nwsgovgo.header.header-size",
            behavior: "plugin.tx_nwsgovgo.header.header-position",
            navigationStyle: "plugin.tx_nwsgovgo.header.header-navigation",
            hasButton: undefined,
            buttonPosition:
                "plugin.tx_nwsgovgo.navigation.header-buttons-position",
            buttonBackgroundColor:
                "plugin.tx_nwsgovgo.navigation.header-buttons-bg",
            buttonStyle: "plugin.tx_nwsgovgo.navigation.header-buttons-style",
            hasBorderTop: "plugin.tx_nwsgovgo.header.header-border",
            borderTopColor: "plugin.tx_nwsgovgo.header.header-border-color",
        },
        navigation: {
            behavior: "plugin.tx_nwsgovgo.navigation.main-position",
            menuBackgroundColor: "plugin.tx_nwsgovgo.navigation.main-bg",
            fontFamily: undefined,
            menuFontColor: undefined,
            burgerMenuButtonColor:
                "plugin.tx_nwsgovgo.navigation.main-toggler-color",
            chevronMainNav: "plugin.tx_nwsgovgo.navigation.main-chevrons",
            topNavigation: "plugin.tx_nwsgovgo.navigation.top",
            topNavigationPosition: "plugin.tx_nwsgovgo.navigation.top-position",
            topNavigationMobile:
                "plugin.tx_nwsgovgo.navigation.top-mobile-hidden",
            topNavigationColor: "plugin.tx_nwsgovgo.navigation.top-color",
            useDividingLines: "plugin.tx_nwsgovgo.navigation.top-separators",
            iconNavigation: undefined,
            iconNavigationBackgroundColor:
                "plugin.tx_nwsgovgo.navigation.icons-bg",
        },
        search: {
            style: "plugin.tx_nwsgovgo.search.search-style",
            searchbarBackgroundColor: "plugin.tx_nwsgovgo.search.search-bg",
            buttonBackgroundColor: "plugin.tx_nwsgovgo.search.search-button-bg",
            buttonStyle: "plugin.tx_nwsgovgo.search.search-button-style",
        },
        weather: {
            hasWeatherWidget: "plugin.tx_nwsgovgo.weather.weather-display",
            buttonColor: "plugin.tx_nwsgovgo.weather.weather-button-bg",
            buttonStyle: "plugin.tx_nwsgovgo.weather.weather-button-style",
        },
        footer: {
            fontColor: "plugin.tx_nwsgovgo.footer.footer-color",
            backgroundColor: "plugin.tx_nwsgovgo.footer.footer-bg",
            showCopyright: "plugin.tx_nwsgovgo.footer.footer-copyright",
            showPrintIcon: "plugin.tx_nwsgovgo.footer.footer-print",
            showBackToTopIcon: "plugin.tx_nwsgovgo.footer.footer-back-to-top",
            useDividingLines: "plugin.tx_nwsgovgo.navigation.footer-separators",
        },
        subpages: {
            hasHeaderImage: undefined,
            hasBreadcrumbMenu: undefined,
            hasBreadcrumbMenuOnSubpages: undefined,
            hasPageTitle: undefined,
            hasPageTitleOnSubpages: undefined,
            pageTitleBackgroundColor: "plugin.tx_nwsgovgo.page.h1-bg",
            breadcrumbBackgroundColor:
                "plugin.tx_nwsgovgo.navigation.breadcrumb-bg",
        },
    },
};
