import React, {
	type CSSProperties,
	type FocusEventHandler,
	type MouseEventHandler,
	useMemo,
} from "react";
import "css/Components/Inputs/TextInput.scss";
import { useTranslation } from "react-i18next";
import FormInputDeveloperBadge from "../Developer/FormInputDeveloperBadge";

const styleInvalid: CSSProperties = {
	border: "2px solid #FFCCCC",
};

const styleDisabled: CSSProperties = {
	cursor: "not-allowed",
	backgroundColor: "#efeded",
};

export default function TextInput({
	label,
	placeholder,
	value,
	description,
	isValid = true,
	disabled = false,
	onClick,
	onChange,
	onFocus,
	onBlur,
}: {
	label?: string;
	placeholder?: string;
	value?: string;
	description?: string;
	isValid?: boolean;
	disabled?: boolean;
	onChange?: (val: string) => void;
	onClick?: MouseEventHandler<HTMLInputElement>;
	onFocus?: FocusEventHandler<HTMLInputElement>;
	onBlur?: FocusEventHandler<HTMLInputElement>;
}) {
	const { t } = useTranslation();

	const finaleStyle = useMemo<CSSProperties>(() => {
		return Object.assign(
			{},
			isValid ? {} : styleInvalid,
			!disabled ? {} : styleDisabled,
		);
	}, [isValid, disabled]);

	return (
		<div className="textInput-frame">
			<span className="label">
				{t(label ?? "components.inputs.color_picker")}
				<FormInputDeveloperBadge formInputId={label ?? "undefined"} />
			</span>
			<label className="textInput">
				<input
					type="text"
					placeholder={placeholder ?? "Vorschautext"}
					value={value}
					onChange={(e) => onChange?.(e.target.value)}
					onClick={onClick}
					onFocus={onFocus}
					onBlur={onBlur}
					disabled={disabled}
					style={finaleStyle}
				/>
			</label>
			{description && (
				<span className="description">{t(description ?? "description")}</span>
			)}
		</div>
	);
}
