import { type LegacyRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "css/Components/PreviewWebsite/BurgerMenu.scss";
import { useForm } from "Providers/FormProvider";
import { getColorHexByKey, getContrastColorHexByKey } from "helpers";
import {
	ChevronDown,
	ChevronRight,
	CloudRainHeavy,
	XCircle,
} from "react-bootstrap-icons";
import HeaderButton from "./HeaderButton";

export default function BurgerMenu({
	visible = false,
	ref,
	onClose,
}: {
	visible?: boolean;
	ref?: LegacyRef<HTMLDivElement>;
	onClose?: () => void;
}) {
	const { t } = useTranslation();
	const [listCollapsed, setListCollapsed] = useState<boolean>(false);
	const { form } = useForm();

	return (
		<>
			{visible && (
				<div className="burger-menu" ref={ref}>
					<span className="title">{t("preview.navbar.menu")}</span>
					<span className="item">
						{t("preview.navbar.sitemap")}
						<ChevronRight color="black" stroke="black" />
					</span>
					<span
						className="item"
						onClick={() => setListCollapsed((prev) => !prev)}
					>
						{t("preview.navbar.menu_item")}
						<ChevronDown color="black" stroke="black" />
					</span>
					{listCollapsed && (
						<>
							<span className="item indented">
								{t("preview.navbar.submenu_item")}
								<ChevronRight color="black" stroke="black" />
							</span>
							<span className="item indented">
								{t("preview.navbar.submenu_item")}
								<ChevronRight color="black" stroke="black" />
							</span>
							<span className="item indented">
								{t("preview.navbar.submenu_item")}
								<ChevronRight color="black" stroke="black" />
							</span>
						</>
					)}
					<span className="item">
						{t("preview.navbar.menu_item")}
						<ChevronRight color="black" stroke="black" />
					</span>
					{form.weather.hasWeatherWidget && (
						<span className="header-buttons">
							<HeaderButton
								icon={<CloudRainHeavy />}
								label="9°"
								style={{
									backgroundColor:
										form.weather.buttonStyle === "fill"
											? getColorHexByKey(form, form.weather.buttonColor)
											: "transparent",
									color:
										form.weather.buttonStyle !== "fill"
											? getColorHexByKey(form, form.weather.buttonColor)
											: getContrastColorHexByKey(
													form,
													form.weather.buttonColor,
												),
									borderColor: getColorHexByKey(form, form.weather.buttonColor),
								}}
							/>
						</span>
					)}
					<span className="close-icon" onClick={() => onClose?.()}>
						<XCircle size={18} />
					</span>
				</div>
			)}
		</>
	);
}
