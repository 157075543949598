import { useForm } from "Providers/FormProvider";
import { DROPDOWN_OPTIONS_COLORS } from "helpers";
import React from "react";
import Dropdown from "tsx/Components/Inputs/Dropdown";
import Toggle from "tsx/Components/Inputs/Toggle";

export default function FooterForm() {
	const { form, setFormFooterValue } = useForm();

	return (
		<div className="form-frame">
			<Dropdown
				form={form}
				value={form.footer.backgroundColor}
				label="forms.footer.background_color"
				onChange={(val: any) => setFormFooterValue("backgroundColor", val)}
				showColorPreview
				options={DROPDOWN_OPTIONS_COLORS}
			/>
			<Dropdown
				form={form}
				value={form.footer.fontColor}
				label="forms.footer.font_color"
				onChange={(val: any) => setFormFooterValue("fontColor", val)}
				showColorPreview
				options={[
					...DROPDOWN_OPTIONS_COLORS,
					{ key: "color.body", value: "body" },
				]}
			/>
			<Toggle
				label="forms.footer.show_copyright"
				value={form.footer.showCopyright}
				onChange={(val) => setFormFooterValue("showCopyright", val)}
			/>
			<Toggle
				label="forms.footer.show_print_icon"
				value={form.footer.showPrintIcon}
				onChange={(val) => setFormFooterValue("showPrintIcon", val)}
			/>
			<Toggle
				label="forms.footer.show_back_to_top_icon"
				value={form.footer.showBackToTopIcon}
				onChange={(val) => setFormFooterValue("showBackToTopIcon", val)}
			/>
			<Toggle
				label="forms.footer.use_dividing_lines"
				value={form.footer.useDividingLines}
				onChange={(val) => setFormFooterValue("useDividingLines", val)}
			/>
		</div>
	);
}
