import type React from "react";
import { createContext, useContext, useState } from "react";
import "css/PreviewWebsite/PreviewWebsite.scss";
import { useForm } from "Providers/FormProvider";
import classNames from "classnames";
import { getColorHexByKey } from "helpers";
import BurgerMenu from "tsx/Components/PreviewWebsite/BurgerMenu";
import FakeLoader from "tsx/Components/PreviewWebsite/FakeLoader";
import IconNavigation from "tsx/Components/PreviewWebsite/IconNavigation";
import type { PreviewWebsitePages } from "types";
import EventsContainer from "./Container/Content/EventsContainer";
import HeadimageContainer from "./Container/Content/HeadimageContainer";
import InterestingPagesContainer from "./Container/Content/InterestingPagesContainer";
import TextContainer from "./Container/Content/TextContainer";
import FooterContainer from "./Container/FooterContainer";
import NavbarContainer from "./Container/Header/NavbarContainer";
import { SearchbarPopup } from "./Container/Header/SearchbarContainer";
import HeaderContainer from "./Container/HeaderContainer";
import BreadcrumbContainer from "./Container/Subpage/BreadcrumbContainer";

const Context = createContext<{
	currentLocation: PreviewWebsitePages;
	setLocation: (location: PreviewWebsitePages) => void;
}>({
	currentLocation: "mainpage",
	setLocation: (location: PreviewWebsitePages) => {},
});

export default function PreviewWebsite({
	searchbarVisible,
	burgerMenuVisible,
	fakeLoaderVisible,
	setSearchbarVisible,
	setBurgerMenuVisible,
	setFakeLoaderVisible,
	onScrollToTop,
}: {
	searchbarVisible: boolean;
	burgerMenuVisible: boolean;
	fakeLoaderVisible: boolean;
	setSearchbarVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setBurgerMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setFakeLoaderVisible: React.Dispatch<React.SetStateAction<boolean>>;
	onScrollToTop?: () => void;
}) {
	const { form } = useForm();

	const [currentLocation, setCurrentLocation] =
		useState<PreviewWebsitePages>("mainpage");

	const setCurrentLocationHandler = (location: PreviewWebsitePages) => {
		if (form.layout.fakeLoader) {
			setFakeLoaderVisible(true);
			setTimeout(() => {
				setFakeLoaderVisible(false);
			}, 1000);
		}
		setCurrentLocation(location);
	};

	return (
		<Context.Provider
			value={{ currentLocation, setLocation: setCurrentLocationHandler }}
		>
			<div
				className={classNames({
					"website-frame": true,
					framed: form.layout.layout === "framed",
				})}
				style={{
					backgroundColor: getColorHexByKey(
						form,
						form.layout.bodyBackgroundColor,
					),
				}}
			>
				<HeaderContainer
					toggleSearchbarVisible={() => setSearchbarVisible((prev) => !prev)}
				/>

				<NavbarContainer
					searchbarVisible={searchbarVisible}
					searchButtonClick={() => setSearchbarVisible((prev) => !prev)}
					burgerMenuClick={() => {
						onScrollToTop?.();
						setBurgerMenuVisible((prev) => !prev);
					}}
				/>
				{currentLocation === "mainpage" && form.subpages.hasHeaderImage && (
					<HeadimageContainer />
				)}

				<div
					className={classNames({
						content: true,
						boxed: form.layout.layout === "boxed",
						framed: form.layout.layout === "framed",
						"outer-shadow": true,
					})}
				>
					<BreadcrumbContainer />
					{currentLocation === "mainpage" && <EventsContainer />}
					{currentLocation === "mainpage" && <TextContainer />}
					{currentLocation === "mainpage" && <InterestingPagesContainer />}
				</div>
				<FooterContainer onScrollToTop={onScrollToTop} />
			</div>
			<SearchbarPopup
				visible={searchbarVisible && form.search.style === "overlay"}
				onClose={() => setSearchbarVisible(false)}
			/>
			<FakeLoader
				visible={fakeLoaderVisible}
				color={getColorHexByKey(form, form.layout.fakeLoaderColor)}
			/>
			<BurgerMenu
				visible={burgerMenuVisible && form.header.navigationStyle === "burger"}
				onClose={() => setBurgerMenuVisible(false)}
			/>
			{form.navigation.iconNavigation && <IconNavigation />}
		</Context.Provider>
	);
}

export const usePreviewWebsiteHandler = () => useContext(Context);
