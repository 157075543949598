import "css/Components/PreviewWebsite/FakeLoader.scss";
import LoadingSpinner from "./LoadingSpinner";

export default function FakeLoader({
	visible,
	color,
}: { visible: boolean; color: string }) {
	return (
		<>
			{visible && (
				<div className="fake-loader">
					<LoadingSpinner color={color} />
				</div>
			)}
		</>
	);
}
