import { getColorHexByKey } from "helpers";
import { Check2, List } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import HeaderButton from "tsx/Components/PreviewWebsite/HeaderButton";
import type { Form } from "types";

export default function BurgerMenuSmall({
	form,
	burgerMenuClick,
	searchButtonClick,
}: {
	form: Form;
	burgerMenuClick?: () => void;
	searchButtonClick?: () => void;
}) {
	const { t } = useTranslation();

	return (
		<>
			<div
				style={{
					display: "flex",
					columnGap: 10,
					justifyContent:
						form.header.logoPosition === "left" ? "flex-end" : "flex-start",
					width: "100%",
					height: 42,
				}}
			>
				<HeaderButton
					icon={
						<List
							size={26}
							fontWeight={600}
							color={getColorHexByKey(
								form,
								form.navigation.burgerMenuButtonColor,
							)}
							stroke={getColorHexByKey(
								form,
								form.navigation.burgerMenuButtonColor,
							)}
						/>
					}
					onClick={() => burgerMenuClick?.()}
					style={{
						backgroundColor: "transparent",
						borderColor: "transparent",
					}}
				/>
				<HeaderButton
					label={t("search")}
					isSearchButton
					onClick={() => searchButtonClick?.()}
				/>
				{form.header.hasButton && (
					<HeaderButton icon={<Check2 />} label="Test BTN" />
				)}
			</div>
		</>
	);
}
