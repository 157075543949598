import React from "react";
import "css/PreviewWebsite/PreviewWebsite.scss";
import { useForm } from "Providers/FormProvider";
import { getColorHexByKey } from "helpers";
import { ChevronRight } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

export default function TextContainer() {
	const { t } = useTranslation();
	const { form } = useForm();

	return (
		<div className="text">
			<h2 className="title-text">{t("preview.text.title")}</h2>
			<p>
				Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
				eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
				voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
				clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
				amet. <br />
				<br />
				Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
				eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
				voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
				clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
				amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
				nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
				sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
				rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
				ipsum dolor sit amet.
				<br />
				<br /> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
				nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
				sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
				rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
				ipsum dolor sit amet.
			</p>
			<h2 className="title-links">{t("preview.text.links.title")}</h2>
			<div className="links">
				{[0, 1, 2].map((index) => (
					<span key={index} className="link">
						<span
							style={{
								color: getColorHexByKey(form, form.general.colorPrimary),
							}}
						>
							{t("preview.navbar.submenu_item")}
						</span>
						<ChevronRight />
					</span>
				))}
			</div>
		</div>
	);
}
