import React from "react";
import "css/PreviewWebsite/Container/BreadcrumbContainer.scss";
import { useForm } from "Providers/FormProvider";
import { getColorHexByKey, getContrastColorHexByKey } from "helpers";
import { ChevronRight, HouseFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { usePreviewWebsiteHandler } from "tsx/PreviewWebsite/PreviewWebsite";

export default function BreadcrumbContainer() {
	const { t } = useTranslation();
	const { form } = useForm();

	const { currentLocation, setLocation } = usePreviewWebsiteHandler();

	return (
		<div className="breadcrumb-container">
			{((form.subpages.hasBreadcrumbMenuOnSubpages &&
				currentLocation === "subpage") ||
				(form.subpages.hasBreadcrumbMenu &&
					currentLocation === "mainpage")) && (
				<div
					className="breadcrumb"
					style={{
						backgroundColor: getColorHexByKey(
							form,
							form.subpages.breadcrumbBackgroundColor,
						),
						color: getContrastColorHexByKey(
							form,
							form.subpages.breadcrumbBackgroundColor,
						),
					}}
				>
					<span className="chevron">
						<HouseFill
							color={getContrastColorHexByKey(
								form,
								form.subpages.breadcrumbBackgroundColor,
							)}
						/>
					</span>
					<span
						style={{
							textDecoration: "underline",
							cursor: "pointer",
						}}
						onClick={() => setLocation("mainpage")}
					>
						demo.govgo.de
					</span>
					<span>
						<ChevronRight
							color={getContrastColorHexByKey(
								form,
								form.subpages.breadcrumbBackgroundColor,
							)}
							stroke={getContrastColorHexByKey(
								form,
								form.subpages.breadcrumbBackgroundColor,
							)}
						/>
					</span>
					<span
						style={{
							textDecoration: "underline",
							cursor: "pointer",
						}}
					>
						{t("preview.navbar.menu_item")}
					</span>
					<span className="chevron">
						<ChevronRight
							color={getContrastColorHexByKey(
								form,
								form.subpages.breadcrumbBackgroundColor,
							)}
							stroke={getContrastColorHexByKey(
								form,
								form.subpages.breadcrumbBackgroundColor,
							)}
						/>
					</span>
					<span>{t("preview.navbar.submenu_item")}</span>
				</div>
			)}
			{((form.subpages.hasPageTitleOnSubpages &&
				currentLocation === "subpage") ||
				(form.subpages.hasPageTitle && currentLocation === "mainpage")) && (
				<div
					className="title"
					style={{
						backgroundColor: getColorHexByKey(
							form,
							form.subpages.pageTitleBackgroundColor,
						),
						color: getContrastColorHexByKey(
							form,
							form.subpages.pageTitleBackgroundColor,
						),
					}}
				>
					{t("preview.navbar.submenu_item")}
				</div>
			)}
		</div>
	);
}
