import { useEffect } from "react";
import { Configuration } from "./Configuration";
import type {
	FormInputState,
	Form,
	FormFooterStates,
	FormGeneralStates,
	FormHeaderStates,
	FormNavigationStates,
	Tab,
	FormSearchStates,
	FormWeatherStates,
	FormLayoutStates,
	FormSubpagesStates,
} from "./types";

export const findTabById = (id: string): Tab | null => {
	return Configuration.tabs.find((tab) => tab.id === id) ?? null;
};

const getFormStateGeneral = <K extends keyof FormGeneralStates>(
	key: K,
): FormInputState => {
	return Configuration.statesFormData.general[key];
};

const getFormStateLayout = <K extends keyof FormLayoutStates>(
	key: K,
): FormInputState => {
	return Configuration.statesFormData.layout[key];
};

const getFormStateHeader = <K extends keyof FormHeaderStates>(
	key: K,
): FormInputState => {
	return Configuration.statesFormData.header[key];
};

const getFormStateNavigaton = <K extends keyof FormNavigationStates>(
	key: K,
): FormInputState => {
	return Configuration.statesFormData.navigation[key];
};

const getFormStateSearch = <K extends keyof FormSearchStates>(
	key: K,
): FormInputState => {
	return Configuration.statesFormData.search[key];
};

const getFormStateWeather = <K extends keyof FormWeatherStates>(
	key: K,
): FormInputState => {
	return Configuration.statesFormData.weather[key];
};

const getFormStateFooter = <K extends keyof FormFooterStates>(
	key: K,
): FormInputState => {
	return Configuration.statesFormData.footer[key];
};

const getFormStateSubpages = <K extends keyof FormSubpagesStates>(
	key: K,
): FormInputState => {
	return Configuration.statesFormData.subpages[key];
};

export const getFormState = (path: string): FormInputState | undefined => {
	const splitPath = toCamelCase(path).split(".");

	switch (splitPath[0]) {
		case "general":
			if (splitPath[1] in Configuration.statesFormData.general) {
				return getFormStateGeneral(splitPath[1] as keyof FormGeneralStates);
			}
			break;
		case "layout":
			if (splitPath[1] in Configuration.statesFormData.layout) {
				return getFormStateLayout(splitPath[1] as keyof FormLayoutStates);
			}
			break;
		case "header":
			if (splitPath[1] in Configuration.statesFormData.header) {
				return getFormStateHeader(splitPath[1] as keyof FormHeaderStates);
			}
			break;
		case "navigation":
			if (splitPath[1] in Configuration.statesFormData.navigation) {
				return getFormStateNavigaton(
					splitPath[1] as keyof FormNavigationStates,
				);
			}
			break;
		case "search":
			if (splitPath[1] in Configuration.statesFormData.search) {
				return getFormStateSearch(splitPath[1] as keyof FormSearchStates);
			}
			break;
		case "weather":
			if (splitPath[1] in Configuration.statesFormData.weather) {
				return getFormStateWeather(splitPath[1] as keyof FormWeatherStates);
			}
			break;
		case "footer":
			if (splitPath[1] in Configuration.statesFormData.footer) {
				return getFormStateFooter(splitPath[1] as keyof FormFooterStates);
			}
			break;
		case "subpages":
			if (splitPath[1] in Configuration.statesFormData.subpages) {
				return getFormStateSubpages(splitPath[1] as keyof FormSubpagesStates);
			}
			break;

		default:
			return undefined;
	}
};

export const DROPDOWN_OPTIONS_COLORS = [
	{
		key: "color.primary",
		value: "primary",
	},
	{
		key: "color.secondary",
		value: "secondary",
	},
	{
		key: "color.success",
		value: "success",
	},
	{
		key: "color.info",
		value: "info",
	},
	{
		key: "color.warning",
		value: "warning",
	},
	{
		key: "color.danger",
		value: "danger",
	},
	{
		key: "color.light",
		value: "light",
	},
	{
		key: "color.dark",
		value: "dark",
	},
	{
		key: "color.white",
		value: "white",
	},
];

export const getColorHexByKey = (form: Form, key: string): string => {
	switch (key) {
		case "primary":
			return form.general.colorPrimary;
		case "secondary":
			return form.general.colorSecondary;
		case "success":
			return Configuration.defaultColors.success;
		case "info":
			return Configuration.defaultColors.info;
		case "warning":
			return Configuration.defaultColors.warning;
		case "danger":
			return Configuration.defaultColors.danger;
		case "light":
			return Configuration.defaultColors.light;
		case "dark":
			return Configuration.defaultColors.dark;
		case "white":
			return Configuration.defaultColors.white;
		case "body":
			return Configuration.defaultColors.body;
		default:
			if (key?.startsWith("#")) {
				return key;
			}
			return form.general.colorPrimary;
	}
};

export const getCurrentDateTimeAsString = (): string => {
	const currentDate = new Date();

	const year = currentDate.getFullYear();
	const month = `0${currentDate.getMonth() + 1}`.slice(-2);
	const day = `0${currentDate.getDate()}`.slice(-2);
	const hour = `0${currentDate.getHours()}`.slice(-2);
	const minute = `0${currentDate.getMinutes()}`.slice(-2);

	return `${year}-${month}-${day}_${hour}:${minute}`;
};

export const toCamelCase = (input: string): string => {
	const parts = input.split(".");
	const transformedParts = parts.map((part, index) => {
		const parts2 = part.split("_");

		if (parts2.length <= 1) {
			return part;
		}
		const transformedParts2 = parts2.map((part2, index2) => {
			if (index2 === 0) {
				return part2;
			}
			return part2.charAt(0).toUpperCase() + part2.slice(1);
		});

		return transformedParts2.join("");
	});
	return transformedParts.join(".");
};

export const useOutsideClick = (ref: any, callback?: () => void) => {
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event: MouseEvent) {
			if (ref.current && !ref.current.contains(event.target)) {
				callback?.();
			}
		}
		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, callback]);
};

export const getContrastColorHexByKey = (form: Form, key: string): string => {
	const hexColor = getColorHexByKey(form, key);

	// Konvertiere den Hex-Farbcode in RGB
	const hexToRgb = (hex: string) => {
		const bigint = Number.parseInt(hex.slice(1), 16);
		const r = (bigint >> 16) & 255;
		const g = (bigint >> 8) & 255;
		const b = bigint & 255;

		return { r, g, b };
	};

	// Berechne den Kontrast basierend auf der Formel für den relativen Helligkeitskontrast (WCAG 2.0)
	const calculateContrast = (color1: string, color2: string): number => {
		const rgb1 = hexToRgb(color1);
		const rgb2 = hexToRgb(color2);

		const luminance = (color: { r: number; g: number; b: number }) => {
			const R =
				color.r / 255 <= 0.03928
					? color.r / 255 / 12.92
					: ((color.r / 255 + 0.055) / 1.055) ** 2.4;
			const G =
				color.g / 255 <= 0.03928
					? color.g / 255 / 12.92
					: ((color.g / 255 + 0.055) / 1.055) ** 2.4;
			const B =
				color.b / 255 <= 0.03928
					? color.b / 255 / 12.92
					: ((color.b / 255 + 0.055) / 1.055) ** 2.4;
			return 0.2126 * R + 0.7152 * G + 0.0722 * B;
		};

		const luminance1 = luminance(rgb1) / 255;
		const luminance2 = luminance(rgb2) / 255;

		const brighter = Math.max(luminance1, luminance2);
		const darker = Math.min(luminance1, luminance2);

		return (brighter + 0.05) / (darker + 0.05);
	};

	// Überprüfe den Kontrast und gib die entsprechende Farbe zurück
	const contrastWithWhite = calculateContrast(hexColor, "#ffffff");
	const contrastWithBlack = calculateContrast(hexColor, "#000000");

	return contrastWithWhite > contrastWithBlack ? "#ffffff" : "#000000";
};


/**
 * Adds a new line after the first occurrence of a specified search string in a multiline string.
 * @param multilineString - The multiline string to modify.
 * @param searchString - The string to search for.
 * @param newLine - The new line to add after the matching line.
 * @returns The modified multiline string.
 */
export const addLineAfterMatch = (multilineString: string, searchString: string, newLine: string): string => {
	
	const lines = multilineString.split('\n');

	for (let i = 0; i < lines.length; i++) {
			if (lines[i].includes(searchString)) {
					lines.splice(i + 1, 0, newLine);
					break;
			}
	}

	return lines.join('\n');
}

/**
 * Removes lines containing a specific search string from a multiline string.
 * @param multilineString - The multiline string to remove lines from.
 * @param searchString - The search string to match lines against.
 * @returns The multiline string with the matching lines removed.
 */
export const removeLines = (multilineString: string, searchString: string): string => {

	const lines = multilineString.split('\n');

	const filteredLines = lines.filter(line => !line.includes(searchString));

	return filteredLines.join('\n');
}