import type React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { Configuration } from "../Configuration";
import type {
	Form,
	FormFooter,
	FormGeneral,
	FormHeader,
	FormLayout,
	FormNavigation,
	FormSearch,
	FormSubpages,
	FormWeather,
	FormKeysConversionMatrix,
} from "../types";
import { addLineAfterMatch, getCurrentDateTimeAsString, removeLines } from "helpers";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const Context = createContext<{
	form: Form;
	updateForm: (val: Partial<Form>) => void;
	setFormGeneralValue: <T extends FormGeneral, K extends keyof FormGeneral>(
		key: K,
		value: T[K],
	) => void;
	setFormLayoutValue: <T extends FormLayout, K extends keyof FormLayout>(
		key: K,
		value: T[K],
	) => void;
	setFormHeaderValue: <T extends FormHeader, K extends keyof FormHeader>(
		key: K,
		value: T[K],
	) => void;
	setFormNavigationValue: <
		T extends FormNavigation,
		K extends keyof FormNavigation,
	>(
		key: K,
		value: T[K],
	) => void;
	setFormSearchValue: <T extends FormSearch, K extends keyof FormSearch>(
		key: K,
		value: T[K],
	) => void;
	setFormWeatherValue: <T extends FormWeather, K extends keyof FormWeather>(
		key: K,
		value: T[K],
	) => void;
	setFormFooterValue: <T extends FormFooter, K extends keyof FormFooter>(
		key: K,
		value: T[K],
	) => void;
	setFormSubpagesValue: <T extends FormSubpages, K extends keyof FormSubpages>(
		key: K,
		value: T[K],
	) => void;
	clearFormCache: () => void;
	resetAllForms: () => void;
	downloadFormAsFile: () => void;
}>({
	form: Configuration.defaultFormData,
	updateForm: () => {},
	setFormGeneralValue: () => {},
	setFormLayoutValue: () => {},
	setFormHeaderValue: () => {},
	setFormNavigationValue: () => {},
	setFormSearchValue: () => {},
	setFormWeatherValue: () => {},
	setFormFooterValue: () => {},
	setFormSubpagesValue: () => {},
	clearFormCache: () => {},
	resetAllForms: () => {},
	downloadFormAsFile: () => {},
});

export function FormProvider({ children }: { children: React.ReactNode }) {
	const [form, setForm] = useState<Form>(getDefaultFormData());

	const updateForm = (val: Partial<Form>) => {
		setForm((form) => ({ ...form, ...val }));
	};

	const setFormGeneralValue = <
		T extends FormGeneral,
		K extends keyof FormGeneral,
	>(
		key: K,
		value: T[K],
	) => {
		setForm((prevForm) => {
			const tempForm = {
				...prevForm,
				general: {
					...prevForm.general,
					[key]: value,
				},
			};

			return runFormCheck(tempForm);
		});
	};

	const setFormLayoutValue = <T extends FormLayout, K extends keyof FormLayout>(
		key: K,
		value: T[K],
	) => {
		setForm((prevForm) => {
			const tempForm = {
				...prevForm,
				layout: {
					...prevForm.layout,
					[key]: value,
				},
			};

			return runFormCheck(tempForm);
		});
	};

	const setFormHeaderValue = <T extends FormHeader, K extends keyof FormHeader>(
		key: K,
		value: T[K],
	) => {
		setForm((prevForm) => {
			const tempForm = {
				...prevForm,
				header: {
					...prevForm.header,
					[key]: value,
				},
			};

			return runFormCheck(tempForm);
		});
	};

	const setFormNavigationValue = <
		T extends FormNavigation,
		K extends keyof FormNavigation,
	>(
		key: K,
		value: T[K],
	) => {
		setForm((prevForm) => {
			const tempForm = {
				...prevForm,
				navigation: {
					...prevForm.navigation,
					[key]: value,
				},
			};

			return runFormCheck(tempForm);
		});
	};

	const setFormSearchValue = <T extends FormSearch, K extends keyof FormSearch>(
		key: K,
		value: T[K],
	) => {
		setForm((prevForm) => {
			const tempForm = {
				...prevForm,
				search: {
					...prevForm.search,
					[key]: value,
				},
			};

			return runFormCheck(tempForm);
		});
	};

	const setFormWeatherValue = <
		T extends FormWeather,
		K extends keyof FormWeather,
	>(
		key: K,
		value: T[K],
	) => {
		setForm((prevForm) => {
			const tempForm = {
				...prevForm,
				weather: {
					...prevForm.weather,
					[key]: value,
				},
			};

			return runFormCheck(tempForm);
		});
	};

	const setFormFooterValue = <T extends FormFooter, K extends keyof FormFooter>(
		key: K,
		value: T[K],
	) => {
		setForm((prevForm) => {
			const tempForm = {
				...prevForm,
				footer: {
					...prevForm.footer,
					[key]: value,
				},
			};

			return runFormCheck(tempForm);
		});
	};

	const setFormSubpagesValue = <
		T extends FormSubpages,
		K extends keyof FormSubpages,
	>(
		key: K,
		value: T[K],
	) => {
		setForm((prevForm) => {
			const tempForm = {
				...prevForm,
				subpages: {
					...prevForm.subpages,
					[key]: value,
				},
			};

			return runFormCheck(tempForm);
		});
	};

	useEffect(() => {
		localStorage.setItem("config", JSON.stringify(form));
	}, [form]);

	const clearFormCache = (): void => {
		console.log("Cleared form Cache!");
		localStorage.removeItem("config");
	};

	const resetAllForms = (): void => {
		console.log("All entries have been reset to their default values!");
		clearFormCache();
		setForm(Configuration.defaultFormData);
	};

	const downloadFormAsFile = (): void => {
		const zip = new JSZip();
		zip.file("govogo_configuration.json", JSON.stringify(form));
		zip.file(
			"typo3_constants.txt",
			concatenateValues(form, Configuration.formKeysConversionMatrix),
		);
		zip.file(
			"typo3_constants_extra.txt",
			concatenateValues(form, Configuration.formKeysConversionMatrix, true),
		);

		zip
			.generateAsync({ type: "blob" })
			.then((content) => {
				saveAs(
					content,
					`${getCurrentDateTimeAsString()}_govogo_configuration.zip`,
				);
			})
			.catch((error) => {
				console.error("Fehler beim Erstellen der Zip-Datei:", error);
			});
	};

	return (
		<Context.Provider
			value={{
				form,
				updateForm,
				setFormGeneralValue,
				setFormLayoutValue,
				setFormHeaderValue,
				setFormNavigationValue,
				setFormSearchValue,
				setFormWeatherValue,
				setFormFooterValue,
				setFormSubpagesValue,
				clearFormCache,
				resetAllForms,
				downloadFormAsFile,
			}}
		>
			{children}
		</Context.Provider>
	);
}

export const useForm = () => useContext(Context);

const getDefaultFormData = (): Form => {
	const jsConfigFromLS = localStorage.getItem("config");

	if (jsConfigFromLS) {
		return JSON.parse(jsConfigFromLS) as Form;
	}

	return Configuration.defaultFormData;
};

const runFormCheck = (form: Form): Form => ({
	...form,
	navigation: {
		...form.navigation,
		behavior: !(
			form.header.size === "large" &&
			form.header.behavior === "relative" &&
			form.layout.layout === "wide"
		)
			? "relative"
			: form.navigation.behavior,
	},
});

const concatenateValues = (
	form: Form,
	matrix: FormKeysConversionMatrix,
	reverseMode = false,
): string => {
	let result = "";

	// Default values
	if (reverseMode === false) {
		result += "plugin.tx_nwsscaffolding.basic.email_admin = info@die-netzwerkstatt.de\n";
		result += "plugin.tx_nwsgovgo.navigation.main = 1\n";
		result += "plugin.tx_nwsgovgo.navigation.footer = 8\n";
		result += "plugin.tx_nwsgovgo.accessibility.accessibility-engine = none\n";
		result += "plugin.tx_nwsgovgo.slick.slick-control = 1\n";
		result += "plugin.tx_nwsgovgo.slick.slick-play-pause = 1\n";

		result += "plugin.tx_nwsgovgo.navigation.header-buttons-breakpoint = md\n";
		result += "plugin.tx_nwsgovgo.search.search-button-breakpoint = md\n";
		result += "plugin.tx_nwsgovgo.navigation.main-offcanvas-toggler-color = primary\n";
	}

	for (const generalKey in matrix.general) {
		if (
			(matrix.general[generalKey as keyof FormGeneral] !== undefined &&
				!reverseMode) ||
			(matrix.general[generalKey as keyof FormGeneral] === undefined &&
				reverseMode)
		) {
			result += `${matrix.general[generalKey as keyof FormGeneral] ?? generalKey} = ${form.general[generalKey as keyof FormGeneral]}\n`;
		}
	}

	for (const layoutKey in matrix.layout) {
		if (
			(matrix.layout[layoutKey as keyof FormLayout] !== undefined &&
				!reverseMode) ||
			(matrix.layout[layoutKey as keyof FormLayout] === undefined &&
				reverseMode)
		) {
			result += `${matrix.layout[layoutKey as keyof FormLayout] ?? layoutKey} = ${form.layout[layoutKey as keyof FormLayout]}\n`;
		}
	}

	for (const headerKey in matrix.header) {
		if (
			(matrix.header[headerKey as keyof FormHeader] !== undefined &&
				!reverseMode) ||
			(matrix.header[headerKey as keyof FormHeader] === undefined &&
				reverseMode)
		) {
			result += `${matrix.header[headerKey as keyof FormHeader] ?? headerKey} = ${form.header[headerKey as keyof FormHeader]}\n`;
		}
	}

	for (const navigationKey in matrix.navigation) {
		if (
			(matrix.navigation[navigationKey as keyof FormNavigation] !== undefined &&
				!reverseMode) ||
			(matrix.navigation[navigationKey as keyof FormNavigation] === undefined &&
				reverseMode)
		) {
			result += `${matrix.navigation[navigationKey as keyof FormNavigation] ?? navigationKey} = ${form.navigation[navigationKey as keyof FormNavigation]}\n`;
		}
	}

	for (const searchKey in matrix.search) {
		if (
			(matrix.search[searchKey as keyof FormSearch] !== undefined &&
				!reverseMode) ||
			(matrix.search[searchKey as keyof FormSearch] === undefined &&
				reverseMode)
		) {
			result += `${matrix.search[searchKey as keyof FormSearch] ?? searchKey} = ${form.search[searchKey as keyof FormSearch]}\n`;
		}
	}

	for (const weatherKey in matrix.weather) {
		if (
			(matrix.weather[weatherKey as keyof FormWeather] !== undefined &&
				!reverseMode) ||
			(matrix.weather[weatherKey as keyof FormWeather] === undefined &&
				reverseMode)
		) {
			result += `${matrix.weather[weatherKey as keyof FormWeather] ?? weatherKey} = ${form.weather[weatherKey as keyof FormWeather]}\n`;
		}
	}

	for (const footerKey in matrix.footer) {
		if (
			(matrix.footer[footerKey as keyof FormFooter] !== undefined &&
				!reverseMode) ||
			(matrix.footer[footerKey as keyof FormFooter] === undefined &&
				reverseMode)
		) {
			result += `${matrix.footer[footerKey as keyof FormFooter] ?? footerKey} = ${form.footer[footerKey as keyof FormFooter]}\n`;
		}
	}

	for (const subpagesKey in matrix.subpages) {
		if (
			(matrix.subpages[subpagesKey as keyof FormSubpages] !== undefined &&
				!reverseMode) ||
			(matrix.subpages[subpagesKey as keyof FormSubpages] === undefined &&
				reverseMode)
		) {
			result += `${matrix.subpages[subpagesKey as keyof FormSubpages] ?? subpagesKey} = ${form.subpages[subpagesKey as keyof FormSubpages]}\n`;
		}
	}

	/**
	 * Convert Export Configuration Content into valid Typo3 Constants (Values)
	*/
	
	result = result.replaceAll("plugin.tx_nwsgovgo.navigation.top = true", "plugin.tx_nwsgovgo.navigation.top = 8");
	result = result.replaceAll("plugin.tx_nwsgovgo.navigation.top = false", "plugin.tx_nwsgovgo.navigation.top = ");

	// Extra Handling for Navigation Chevrons
	result = addLineAfterMatch(result, "plugin.tx_nwsgovgo.navigation.main-chevrons", `plugin.tx_nwsgovgo.navigation.main-dropdown-chevrons = ${form.navigation.chevronMainNav}`);
	result = addLineAfterMatch(result, "plugin.tx_nwsgovgo.navigation.main-dropdown-chevrons", `plugin.tx_nwsgovgo.navigation.main-mega-menu-chevrons = ${form.navigation.chevronMainNav}`);

	// Convert boolean values to 1 and 0
	result = result.replaceAll("= true", "= 1");
	result = result.replaceAll("= false", "= 0");

	// Convert flex-start and flex-end to start and end
	result = result.replaceAll("= flex-start", "= start");
	result = result.replaceAll("= flex-end", "= end");

	// Display of top navigation for mobile devices, swap values as our value is aimed at mobile deactivation
	result = result.replaceAll("plugin.tx_nwsgovgo.navigation.top-mobile-hidden = 1", "plugin.tx_nwsgovgo.navigation.top-mobile-hidden = 0");
	result = result.replaceAll("plugin.tx_nwsgovgo.navigation.top-mobile-hidden = 0", "plugin.tx_nwsgovgo.navigation.top-mobile-hidden = 1");

  // Fixes for iconNavigation and hasButton
	result = addLineAfterMatch(result, "plugin.tx_nwsgovgo.navigation.header-buttons-style", `plugin.tx_nwsgovgo.navigation.header-buttons = ${form.header.hasButton ? 22 : ''}`);
	result = addLineAfterMatch(result, "plugin.tx_nwsgovgo.navigation.icons-bg", `plugin.tx_nwsgovgo.navigation.icons = ${form.navigation.iconNavigation ? 21 : ''}`);
	if (reverseMode) {
		result = removeLines(result, "hasButton");
		result = removeLines(result, "iconNavigation");
	}
	
	return result;
};
