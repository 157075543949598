import React, { useRef } from "react";
import "css/PreviewWebsite/Container/SearchbarContainer.scss";
import { useForm } from "Providers/FormProvider";
import classNames from "classnames";
import { getColorHexByKey, useOutsideClick } from "helpers";
import { XCircle } from "react-bootstrap-icons";
import SearchInput from "tsx/Components/PreviewWebsite/SearchInput";

export default function SearchbarContainer({
	visible = false,
}: {
	visible?: boolean;
}) {
	const { form } = useForm();

	return (
		<div
			className={classNames({ searchbar: true, visible: visible })}
			style={{
				backgroundColor: getColorHexByKey(
					form,
					form.search.searchbarBackgroundColor,
				),
				top:
					form.header.behavior !== "relative" &&
					form.navigation.behavior === "relative"
						? form.header.hasBorderTop
							? 146
							: 140
						: form.navigation.behavior !== "relative"
							? form.header.hasBorderTop
								? 96
								: 90
							: 0,
				position:
					form.header.behavior !== "relative" ||
					form.navigation.behavior !== "relative"
						? "sticky"
						: "relative",
			}}
		>
			<SearchInput />
		</div>
	);
}

export function SearchbarPopup({
	visible = false,
	onClose,
}: {
	visible?: boolean;
	onClose?: () => void;
}) {
	const wrapperRef = useRef(null);

	useOutsideClick(wrapperRef, () => onClose?.());

	return (
		<>
			{visible && (
				<div className="searchbar-popup-bg">
					<div className="searchbar-popup-wrapper" ref={wrapperRef}>
						<span className="title">Suchen</span>
						<XCircle
							color="#212529"
							size={20}
							style={{ position: "absolute", right: 30, top: 28 }}
							onClick={() => onClose?.()}
						/>
						<span className="searchbar-wrapper">
							<div className="searchbar visible">
								<SearchInput />
							</div>
						</span>
					</div>
				</div>
			)}
		</>
	);
}
