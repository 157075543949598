import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Configuration } from "../Configuration";
import { Tab } from "../types";
import { findTabById } from "../helpers";

const Context = createContext<{
    currentLocation: string;
    currentTab: Tab | null;
}>({
    currentLocation: Configuration.defaultSubsiteId,
    currentTab: null,
});

export function PageLocationProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const { subsite } = useParams();
    const [currentLocation, setCurrentLocation] = useState<string>(
        Configuration.defaultSubsiteId
    );
    const [currentTab, setCurrentTab] = useState<Tab | null>(null);

    useEffect(() => {
        if (subsite !== undefined && currentLocation !== subsite) {
            setCurrentLocation(subsite);
            setCurrentTab(findTabById(subsite));
        } else if (subsite === undefined) {
            setCurrentLocation(Configuration.defaultSubsiteId);
        }
    }, [subsite, currentLocation]);

    return (
        <Context.Provider value={{ currentLocation, currentTab }}>
            {children}
        </Context.Provider>
    );
}

export const usePageLocation = () => useContext(Context);
