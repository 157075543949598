import { Search } from "react-bootstrap-icons";
import "css/Components/PreviewWebsite/HeaderButton.scss";
import { useForm } from "Providers/FormProvider";
import { getColorHexByKey, getContrastColorHexByKey } from "helpers";
import type React from "react";
import { type CSSProperties, useMemo } from "react";

export default function HeaderButton({
	label,
	icon,
	style,
	isSearchButton = false,
	onClick,
}: {
	label?: string;
	icon?: React.ReactNode;
	style?: CSSProperties;
	isSearchButton?: boolean;
	onClick?: () => void;
}) {
	const { form } = useForm();

	const finaleStyle = useMemo<CSSProperties>(
		() => ({
			backgroundColor: !isSearchButton
				? form.header.buttonStyle === "fill"
					? getColorHexByKey(form, form.header.buttonBackgroundColor)
					: "transparent"
				: form.search.buttonStyle === "fill"
					? getColorHexByKey(form, form.search.buttonBackgroundColor)
					: "transparent",

			borderWidth: "1px",
			borderStyle: "solid",
			borderColor: getColorHexByKey(
				form,
				isSearchButton
					? form.search.buttonBackgroundColor
					: form.header.buttonBackgroundColor,
			),
			color: getColorHexByKey(
				form,
				!isSearchButton
					? form.header.buttonStyle === "outline"
						? form.header.buttonBackgroundColor
						: getContrastColorHexByKey(form, form.header.buttonBackgroundColor)
					: form.search.buttonStyle === "outline"
						? form.search.buttonBackgroundColor
						: getContrastColorHexByKey(form, form.search.buttonBackgroundColor),
			),
		}),
		[form, isSearchButton],
	);

	return (
		<span
			className="header-button"
			style={{ ...finaleStyle, ...style }}
			onClick={() => onClick?.()}
		>
			{isSearchButton && <Search />}
			{!isSearchButton && icon}
			{label && <span>{label}</span>}
		</span>
	);
}
