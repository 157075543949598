import { useForm } from "Providers/FormProvider";
import React from "react";
import ColorPicker from "tsx/Components/Inputs/ColorPicker";
import TextInput from "tsx/Components/Inputs/TextInput";
import Toggle from "tsx/Components/Inputs/Toggle";

export default function GeneralForm() {
	const { form, setFormGeneralValue } = useForm();

	return (
		<div className="form-frame">
			<TextInput
				label="forms.general.institution_name"
				value={form.general.institutionName}
				onChange={(val: string) => setFormGeneralValue("institutionName", val)}
			/>
			<TextInput
				label="forms.general.domain_name"
				value={form.general.domainName}
				onChange={(val: string) => setFormGeneralValue("domainName", val)}
			/>
			<Toggle
				label="forms.general.domain_exists"
				value={form.general.domainExists}
				onChange={(val: boolean) => setFormGeneralValue("domainExists", val)}
			/>
			<TextInput
				label="forms.general.font_family_body"
				value={form.general.fontFamilyBody}
				onChange={(val: string) => setFormGeneralValue("fontFamilyBody", val)}
			/>
			<TextInput
				label="forms.general.font_family_title"
				value={form.general.fontFamilyTitle}
				onChange={(val: string) => setFormGeneralValue("fontFamilyTitle", val)}
			/>
			<ColorPicker
				label="forms.general.color_primary"
				value={form.general.colorPrimary}
				onChange={(val: string) => setFormGeneralValue("colorPrimary", val)}
			/>
			<ColorPicker
				label="forms.general.color_secondary"
				value={form.general.colorSecondary}
				onChange={(val: string) => setFormGeneralValue("colorSecondary", val)}
			/>
		</div>
	);
}
